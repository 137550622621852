import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
  .mobile-menu-area {
    padding-top: 95px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: none;

    &:before {
      position: absolute;
      content: "";
      background: rgba(102, 42, 48, 0.85);

      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    @media (max-width: 442px) {
      padding-top: 120px;
    }

    .mb-topbar {
      position: relative;
      z-index: 2;
      border-bottom: 1px solid ${colors.text6};
      .topbar-item {
        p {
          font-size: 13px;
          color: ${colors.text4};
          padding: 8px 0;
          i {
            font-size: 16px;
            color: ${colors.bg2};
            vertical-align: text-top;
            margin-right: 5px;
          }
          a {
            color: ${colors.text6};
          }
        }
        ul {
          padding: 8px 0;
          li {
            a {
              font-size: 13px;
              color: ${colors.bg2};
              font-weight: 500;
              text-transform: uppercase;
              &:hover {
                color: #ffffff;
              }
            }
            &:nth-child(2) {
              color: ${colors.text3};
            }
          }
        }
      }
    }

    .mb-logo-area {
      padding: 18px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      
      .mb-logo-box {
        display: flex;
        align-items: center;
        .hm-button {
          margin-top: 8px;
          margin-right: 35px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            background: ${colors.text6};
            width: 1px;
            height: 25px;
            top: -4px;
            right: -16px;
          }

          i {
            font-size: 20px;
            color: ${colors.bg2};
          }

          @media (max-width: 480px) {
            margin-top: 6px;
            margin-right: 8px;
            &:before {
              content: none;
            }
          }
        }
        .mb-logo {
          a {
            img {
              max-width: 150px;
            }
          }
        }
      }

      ul.bar-lang {
        position: relative;
        margin-top: 3px;

        li {
          .dropdown {
            button.dropdown-toggle {
              font-size: 13px;
              color: ${colors.text6};
              background: transparent;
              border: none;
              padding: 0;
              box-shadow: none;

              img {
                margin-right: 5px;
                max-width: 21px;
              }

              i {
                font-size: 12px;
                margin-left: 3px;
              }

              &::after {
                display: none;
              }

              &:hover {
                cursor: pointer;
                color: ${colors.bg2};
              }
            }

            ul.dropdown-menu {
              padding: 0;
              margin: 0;
              border: none;
              background-color: #ffffff;
              border-radius: 8px;
              top: 50% !important;
              left: -16px !important;
              box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

              li {
                display: block;
                border-bottom: 1px solid ${colors.border1};
                padding: 7px 15px;
                color: ${colors.black1};
                cursor: pointer;

                img {
                  max-width: 21px;
                  margin-right: 5px;
                }

                &:hover {
                  color: ${colors.bg2};
                  font-weight: 500;
                  background-color: transparent;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 1000px) {
      display: block;
    }
  }

  .mb-sidebar {
    background: #ffffff;
    height: 100%;
    width: 320px;
    position: fixed;
    top: 0;
    left: -320px;
    overflow-y: auto;
    z-index: 9999;
    transition: all 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    display: none;
    .mb-sidebar-heading {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      padding: 25px;

      &:before {
        position: absolute;
        content: "";
        background: rgba(102, 42, 48, 0.85);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      h5 {
        z-index: 21;
        color: #ffffff;
        text-transform: uppercase;
      }
      .close-mb-sidebar {
        z-index: 22;
        i {
          font-size: 22px;
          color: #ffffff;
        }
      }
    }
    .mb-sidebar-menu {
      padding: 25px;
      .mb-menu-item {
        border-top: 1px solid ${colors.border1};
        font-weight: 500;

        &:last-child {
          border-bottom: 1px solid ${colors.border1};
        }
        &:first-child {
          border-top: none;
        }
        button.mb-menu-button {
          border: none;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px 0;
          text-align: left;
          text-transform: uppercase;
          font-weight: 500;
          color: ${colors.black1};

          .accordion-icon {
            transition: transform 0.3s ease;
          }

          .icon-rotate {
            transform: rotate(90deg);
          }

          a {
            color: ${colors.black1};
            text-transform: uppercase;
            font-weight: 500;

            i {
              font-size: 13px;
              float: right;
              border: 1px solid ${colors.border3};
              border-radius: 25px;
              padding: 3px;
            }
            &:hover {
              color: ${colors.bg1};
              i {
                border-color: ${colors.bg1};
              }
            }
          }
        }

        .mb-menu-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-in-out;

          ul {
            li {
              border-top: 1px solid ${colors.border1};
              a {
                font-size: 13px;
                color: ${colors.black2};
                display: block;
                padding: 10px 0;
                padding-left: 15px;
                &:hover {
                  color: ${colors.green};
                }
              }
            }
          }
        }

        .mb-menu-content.show {
          max-height: 100%;
        }
      }
    }

    @media (max-width: 1000px) {
      display: block;
    }

    @media (max-width: 480px) {
      max-width: 275px;
    }
  }

  .mb-sidebar.opened {
    left: 0 !important;
  }

  .mb-sidebar-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1111;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease;
  }

  .mb-sidebar-overlay.visible {
    visibility: visible;
    opacity: 1;
  }
`;
