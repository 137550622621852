import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles";
import EnglishData from "../../data/students-union/data.json";
import RussianData from "../../data/students-union/data-ru.json";
import UzbekData from "../../data/students-union/data-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { colors } from "../../components/common/element/elements";
import { Icon } from "@iconify/react";
import Heading from "../../components/shared/Heading";

const StudentsUnion = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper student-voice-page">
      <BreadcrumbBox
        title="Students Union"
        image="/assets/images/students-union.webp"
      />
      <Styles>
        <section className="students-union-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="students-union-content">
                  <div className="section">
                    {data?.section1?.paragraphs?.map((item, index) => (
                      <p className="pb-3" key={index}>
                        {item}
                      </p>
                    ))}

                    <ul className="list-unstyled">
                      {data?.section1?.list?.map((item, index) => (
                        <li className="d-flex mb-3 item" key={index}>
                          <div>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={20}
                              color={colors.bg2}
                            />
                          </div>
                          <p>{item}</p>
                        </li>
                      ))}
                    </ul>

                    <p className="pb-3">{data?.section1?.subtitle}</p>
                  </div>

                  <div className="section">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data?.section2?.title}
                    />
                    <p className="pb-3">{data?.section2?.subtitle1}</p>
                    <p className="pb-3">
                      {data?.section2?.subtitle2?.prelink}{" "}
                      <a href={`mailto:${data?.section2?.subtitle2?.link}`}>
                        {data?.section2?.subtitle2?.link}
                      </a>
                      {data?.section2?.subtitle2?.postlink}
                    </p>
                  </div>

                  <div className="section">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data?.section3?.title}
                    />
                    <p className="pb-3">{data?.section3?.subtitle1}</p>
                    <h6 className="pb-3">{data?.section3?.subtitle2}</h6>
                    <ul className="list-unstyled">
                      {data?.section3?.list?.map((item, index) => (
                        <li className="d-flex mb-3 item" key={index}>
                          <div>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={20}
                              color={colors.bg2}
                            />
                          </div>
                          <p>{item}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default StudentsUnion;
