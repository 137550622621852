import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BreadcrumbBox from "../../components/common/Breadcrumb";
import Button from "../../components/shared/Button";

import { Styles } from "./styles/apply.js";

import EnglishData from "../../data/course/filter.json";
import RussianData from "../../data/course/filter-rus.json";
import UzbekData from "../../data/course/filter-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import HelmetComponent from "../../components/helmet/HelmetComponent.jsx";

const Apply = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [courseData] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <HelmetComponent title="Apply" description={""} keywords={""} />
        <BreadcrumbBox title="Apply" image="/assets/images/banner-1.jpg" />
        <section className="registration-area">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col
                md="4"
                className="mb-4 d-flex justify-content-center align-items-center apply-navigation p-0"
              >
                <div className="apply-navigation-active right px-5 py-3 text-center">
                  {t("For Applicants")}
                </div>
                <div
                  className="apply-navigation px-5 py-3 text-center left"
                  onClick={() => navigate("/apply-practitioners")}
                >
                  {t("For Practitioners")}
                </div>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col lg="12" md="12">
                    <h4 className="strategy-title">
                      {t("University Programmes")}
                    </h4>
                  </Col>
                  {courseData?.dataList
                    ?.filter((course) => course?.type !== "cpd")
                    .map((data) => (
                      <Col lg="4" md="4" key={data?.id}>
                        <div className="course-item">
                          <div
                            className="course-image"
                            style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})`,
                            }}
                          ></div>

                          <div className="course-content">
                            <h6 className="heading text-center">
                              <span> {data?.courseTitle} </span>
                              {data?.courseSubtitle}
                              <span> {data?.courseTitle2} </span>
                            </h6>
                          </div>
                          <Button
                            className="apply-button"
                            wrapperClassName="apply-button-wrapper"
                            onClick={() =>
                              navigate(
                                `/students-apply-form?programme=${data?.courseEnum}`,
                                {
                                  state: {
                                    facultyType: data?.courseEnum,
                                  },
                                }
                              )
                            }
                            text="Apply Now"
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col lg="12" md="12">
                    <h4 className="strategy-title">
                      {t("School Competitions")}
                    </h4>
                  </Col>
                  <Col lg="4" md="4">
                    <div className="course-item">
                      <div
                        className="course-image"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/olympiad-1.webp)`,
                        }}
                      ></div>

                      <div className="course-content">
                        <h6 className="heading text-center">
                          <span>{t("Life Sciences Olympiad")}</span>
                        </h6>
                      </div>
                      <Button
                        className="apply-button"
                        wrapperClassName="apply-button-wrapper"
                        onClick={() => navigate("/olympiad/form")}
                        text="Register Now"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default Apply;
