import styled from "styled-components";
import { colors } from "./../../../components/common/element/elements.js";

export const Styles = styled.div`
  /* Popular Course */
  .popular-course {
    box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
    padding: 16px 16px 16px;
    border-radius: 8px;
    margin-bottom: 30px;

    h5 {
      color: ${colors.black1};
      font-weight: 600;
      padding-bottom: 10px;
      margin-bottom: 20px;
      position: relative;
      font-size: 18px;

      &:before {
        position: absolute;
        content: "";
        background: ${colors.bg1};
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 575px) {
        font-size: 17px;
      }
    }

    .popular-items {
      .item-box {
        display: flex;
        align-items: start;
        margin-bottom: 15px;

        .item-img {
          flex-shrink: 0;

          img {
            width: 65px;
            height: 65px;
            object-fit: cover;
            border-radius: 8px;
            margin-right: 12px;

            @media (max-width: 1199px) {
              max-width: 60px;
              margin-right: 12px;
            }
          }
        }

        .item-content {
          p.title {
            font-size: 12px;
            color: ${colors.black2};
            font-weight: 500;
            line-height: 16px;
            span {
              font-weight: 550;
            }
            &:hover {
              color: ${colors.bg2};
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media (max-width: 1199px) {
      padding: 12px 15px 15px;
    }
  }
`;
