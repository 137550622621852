import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import VacancyItem from "../../components/career/VacancyItem";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Heading from "../../components/shared/Heading";
import {
  UOS_Regulations,
  DMU_Regulations,
  PTU_Regulations,
} from "../../data/regulations/regulations";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "../../data/regulations/translations/data.json";
import RussianData from "../../data/regulations/translations/data-ru.json";
import UzbekData from "../../data/regulations/translations/data-uz.json";

const AcademicRegulations = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper strategy-page">
      <BreadcrumbBox
        title="Academic Regulations"
        image="/assets/images/academic_regulations.jpg"
      />
      <section className="strategy-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="pt-5" id="policies">
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={"PTU regulations and policies"}
                />
                <p className="pb-4">
                  The regulations below apply to all PTU students and serve to
                  outline expectations of you, as well as what can expect from
                  us.
                </p>
                {PTU_Regulations?.map((regulation) => (
                  <VacancyItem
                    key={regulation.document}
                    title={regulation.title}
                    buttonsToRender={[
                      {
                        title: "View",
                        onClick: () => {
                          const element = document.createElement("a");
                          element.href = regulation.document;
                          element.target = "_blank";

                          element.download = regulation.documentName;
                          document.body.appendChild(element);
                          element.click();
                        },
                      },
                    ]}
                  />
                ))}
              </div>

              <div id="dmu" className="pt-5">
                <Heading
                  tag="h4"
                  className="strategy-title mt-5"
                  title={data.dmu.title}
                />
                <div className="d-flex flex-column gap-2 pb-4">
                  <p>{data.dmu.paragraph1}</p>
                  <p>
                    {data.dmu.paragraph2}{" "}
                    <a
                      target="_blank"
                      href="https://www.dmu.ac.uk/current-students/student-support/academic-support/regulations/index.aspx"
                    >
                      {data.dmu.linkTitle}
                    </a>
                  </p>
                  <p>{data.dmu.paragraph3}</p>
                </div>
                {DMU_Regulations?.map((regulation) => (
                  <VacancyItem
                    key={regulation.document}
                    title={regulation.title}
                    buttonsToRender={[
                      {
                        title: "View",
                        onClick: () => {
                          const element = document.createElement("a");
                          element.href = regulation.document;
                          element.target = "_blank";

                          element.download = regulation.documentName;
                          document.body.appendChild(element);
                          element.click();
                        },
                      },
                    ]}
                  />
                ))}
              </div>

              <div id="uos" className="pt-5">
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={data.uos.title}
                />
                <div className="d-flex flex-column gap-2 pb-4">
                  <p>{data.uos.paragraph1}</p>
                  <p>
                    {data.uos.paragraph2}{" "}
                    <a
                      target="_blank"
                      href="https://services.sunderland.ac.uk/academicregistry/studenthandbook/?slug=academic-regulations-and-student-guide"
                    >
                      {data.uos.linkTitle}
                    </a>
                  </p>
                  <p>{data.uos.paragraph3}</p>
                </div>
                {UOS_Regulations?.map((regulation) => (
                  <VacancyItem
                    key={regulation.document}
                    title={regulation.title}
                    buttonsToRender={[
                      {
                        title: "View",
                        onClick: () => {
                          const element = document.createElement("a");
                          element.href = regulation.document;
                          element.target = "_blank";

                          element.download = regulation.documentName;
                          document.body.appendChild(element);
                          element.click();
                        },
                      },
                    ]}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default AcademicRegulations;
