import styled from "styled-components";
import { colors } from "../../components/common/element/elements";

export const Styles = styled.div`
  .opendays-section {
    padding: 32px 0;

    .opendays-calendar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0;
    }

    ul {
      padding: 12px 0;
      li {
        display: flex;
        align-items: center;
        column-gap: 10px;
        border-radius: 8px;
        box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
        padding: 10px 15px;
        font-size: 15px;
        color: ${colors.text3};
        line-height: 25px;
        margin-bottom: 15px;
      }

      li.item {
        i {
          color: ${colors.bg1};
          font-size: 20px;
        }
      }
    }

    .fc-daygrid-event {
      background-color: ${colors.bg1} !important;
      color: white !important;
      border-radius: 4px;
      padding: 2px;
      border: none;
      padding-left: 6px;
    }
    .fc-toolbar-title {
      color: ${colors.bg1} !important;
      font-size: 16px !important;
    }
    .fc-daygrid-day-number {
      color: ${colors.bg1} !important;
    }
    .fc .fc-col-header-cell {
      background-color: ${colors.bg2} !important;
      color: white !important;
      font-weight: bold;
    }

    .fc .fc-button {
      background-color: ${colors.bg1} !important;
      color: white !important;
      border-radius: 5px;
      border: none !important;
      box-shadow: none !important;
    }

    .fc .fc-button:hover {
      background-color: ${colors.bg2} !important;
    }

    .fc .fc-col-header-cell-cushion {
      color: white !important;
      font-weight: bold;
    }
  }
`;
