import React from "react";
import { Link } from "react-router-dom";
const logoTypes = {
  light: "/assets/images/logo-white.webp",
  dark: "/assets/images/logo.webp",
};

export const Logo = ({ type = "light" }) => {
  return (
    <div className="logo">
      <Link to={"/"}>
        <img src={logoTypes[type]} alt="PTU logo" />
      </Link>
    </div>
  );
};
