import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

import { LANGUAGE_LEVELS } from "../../utilities/constants";
import { useFieldArray } from "react-hook-form";

const LanguageInputsComponent = ({
  register,
  control,
  errors,
  watch,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "languages",
  });

  return (
    <>
      <Col md="12">
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Language"}
                  type="text"
                  placeholder={"Language Name"}
                  name={`languages[${index}].language`}
                  register={register}
                  error={errors?.languages?.[index]?.language}
                  required
                />
              </Col>
              <Col md="6">
                <div className="form-control">
                  <label htmlFor={"select"}>
                    {"Level"}
                    <strong className="text-danger">*</strong>
                  </label>
                  <select
                    id={"select"}
                    value={watch(`languages[${index}].level`)}
                    onChange={(e) =>
                      setValue(`languages[${index}].level`, e.target.value)
                    }
                  >
                    {Object.values(LANGUAGE_LEVELS).map((language) => (
                      <option value={language} key={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              {fields.length > 1 && (
                <Col md="12" className="d-flex justify-content-end">
                  <Button
                    text={"Remove"}
                    iconName="ph:trash"
                    onClick={() => remove(index)}
                  />
                </Col>
              )}
            </Row>
          );
        })}
      </Col>
      <Col md="12" className="d-flex justify-content-end">
        <Button
          text={"Add a row"}
          className="outline"
          iconName={"ph:plus-bold"}
          onClick={() => append({ id: uuidv4() })}
        />
      </Col>
    </>
  );
};

export default LanguageInputsComponent;
