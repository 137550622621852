import React, { useCallback } from "react";
import { motion } from "framer-motion";
import { Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useNavigate } from "react-router-dom";

import Fetcher from "../../../components/shared/Fetcher";
import Heading from "../../../components/shared/Heading";

import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import { useQueryParamByName } from "../../../hooks/useQueryParamByName";
import newsService from "../../service/newsService";
import { IMAGE_KEYS, LANGUAGE_KEYS } from "../../../utilities/constants";

const HorizontalTopNewsComponent = () => {
  const navigate = useNavigate();
  const { newsState, newsDispatch } = useNewsPageContext();
  const currentPageQueryString = useQueryParamByName("current-page");
  const pageSizeQueryString = useQueryParamByName("page-size");

  const fetchTopNews = useCallback(
    async () =>
      await newsService.getNews({
        isAtTop: true,
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedTopNews = useCallback(
    (response) => {
      newsDispatch({ type: "topNewsFetch", response: response });
    },
    [newsDispatch]
  );

  return (
    <Col md="12" className="top-story-section">
      <Fetcher action={fetchTopNews} onLoad={handleOnLoadFetchedTopNews}>
        <Heading tag="h4" title={"Top Stories"} className="news-title" />
        <div className="top-story-container">
          <Swiper
            modules={[Autoplay]}
            slidesPerView={4}
            loop={true}
            speed={1500}
            autoplay={{ delay: 4000, disableOnInteraction: true }}
            spaceBetween={30}
            breakpoints={{
              0: { slidesPerView: 1 },
              576: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              992: { slidesPerView: 4 },
            }}
          >
            {newsState?.topNews?.map((news) => (
              <SwiperSlide key={news?.id}>
                <motion.div
                  className="top-story"
                  whileHover={{ scale: 1.02 }}
                  onClick={() => navigate(`/news/${news?.id}`)}
                >
                  <div
                    className="top-stories-image"
                    style={{
                      backgroundImage: `url(${
                        news?.imageUrls?.[LANGUAGE_KEYS.EN]?.[
                          IMAGE_KEYS.COVER_IMAGE_GET
                        ]
                      })`,
                    }}
                  ></div>
                  <h6>{news?.categoryName?.[LANGUAGE_KEYS.EN]}</h6>
                  <article>{news.title[LANGUAGE_KEYS.EN]}</article>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Fetcher>
    </Col>
  );
};

export default HorizontalTopNewsComponent;
