import React from "react";

import { Styles } from "./styles/socialMediaComponent.js";

const SocialMediaComponent = ({ primary = false }) => {
  return (
    <Styles>
      <ul className="social list-unstyled list-inline d-flex align-items-center">
        <li className="list-inline-item">
          <a
            href={"https://www.youtube.com/channel/UC82Kw_eKn67529p8mQFGuvw"}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href={"https://www.instagram.com/pharmatechuni/"}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href={
              "https://www.linkedin.com/company/pharmaceutical-technical-university"
            }
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href={"https://t.me/ptuuz"}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center"
          >
            <i className="fab fa-telegram"></i>
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href={"https://www.facebook.com/PharmaceuticalTechnicalUniversity"}
            target="_blank"
            rel="noopener noreferrer"
            className="d-flex align-items-center"
          >
            <i className="fab fa-facebook"></i>
          </a>
        </li>
      </ul>
    </Styles>
  );
};

export default SocialMediaComponent;
