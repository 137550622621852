import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Styles } from "./styles/programmeBreadcrubmb.js";
import Button from "../shared/Button.jsx";

export const ProgrammeBreadCrumb = ({
  title,
  previousPath = "/",
  image = "/assets/images/test.jpg",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Styles>
      <section
        className="breadcrumb-area"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}${image})`,
        }}
      >
        <Container>
          <Row>
            <Col md="12">
              <div className="breadcrumb-box">
                <h2 className="breadcrumb-title">{t(title)}</h2>
                <Button text="Apply Now" onClick={() => navigate(previousPath)} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

