import React from "react";

import { useLanguageDetector } from "../../hooks/useLanguageDetector.js";

import HeroSlider from "../../components/HeroSlider";
import IconBox from "../../components/IconBox";
import HomeAboutUs from "../../components/HomeAboutUs";
import CourseFilter from "../../components/CourseFilter";
import PartnersSlider from "../../components/PartnersSlider";
import HelpArea from "../../components/HelpArea";
import LatestNewsComponent from "../../newsPage/components/smartComponents/LatestNewsComponent";

import Data from "../../data/icon-box/icon-box.json";
import DataRu from "../../data/icon-box/icon-box-rus.json";
import DataUz from "../../data/icon-box/icon-box-uz.json";
import HelmetComponent from "./../../components/helmet/HelmetComponent";

const Home = () => {
  const [iconBoxData] = useLanguageDetector(Data, DataRu, DataUz);

  return (
    <div className="main-wrapper">
      <HelmetComponent
        title="Home"
        description={"Farmatsevtika Texnik Universiteti"}
        keywords={
          "Pharmaceutical Technical University, PTU, Farmatsevtika Texnik Universiteti, FTU, Фармацевтический Технический Университет, ФТУ"
        }
      />
      <HeroSlider />
      <IconBox data={iconBoxData} />
      <HomeAboutUs />
      <LatestNewsComponent />
      <CourseFilter />
      <HelpArea />
      <PartnersSlider />
    </div>
  );
};

export default Home;
