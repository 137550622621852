import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import BreadcrumbBox from "../../components/common/Breadcrumb";

import { useLanguageDetector } from "../../hooks/useLanguageDetector";

import EnglishData from "../../data/about-us/about-us.json";
import RussianData from "../../data/about-us/about-us-rus.json";
import UzbekData from "../../data/about-us/about-us-uz.json";

import "./styles/About.css";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements";
import HelmetComponent from "./../../components/helmet/HelmetComponent";

const About = () => {
  const { t } = useTranslation();
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper about-page">
      <HelmetComponent title="About Us" description={""} keywords={""} />
      <BreadcrumbBox title="About Us" image="/assets/images/banner-1.jpg" />
      <section className="strategy-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="about-objectives-wrapper">
                <div className="about-desc-wrapper">
                  <p className="about-desc mb-3">{data.pageDescription1}</p>
                  <p className="about-desc mb-3">{data.pageDescription2}</p>
                </div>
                <img
                  className="about-us-image"
                  src={`/assets/images/${data.pageImage}`}
                  alt="PharmaTechUni"
                />
              </div>
              <p className="about-desc mb-3">{data.pageDescription3}</p>
              <p className="about-desc">{data.pageDescription4}</p>
              <h4 className="strategy-title">{t("Our Aim")}</h4>
              <ul className="list-unstyled about-list">
                {data?.objectives.map((objective) => (
                  <li key={objective} className="about-list-item">
                    <div>
                      <Icon
                        icon="ph:check-circle-duotone"
                        fontSize={30}
                        color={colors.bg2}
                      />
                    </div>
                    <p className="pl-1 about-list-item-text">{objective}</p>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
