import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .main-wrapper {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .stage {
    border-radius: 10px;
    padding: 40px;
    margin-bottom: 20px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 10%);

    h3 {
      color: #2c3e50;
      font-size: 1.3rem;
    }

    .results {
      border-left: 4px solid ${colors.bg1};
      padding-left: 16px;
      border-radius: 4px;
    }

    p {
      color: #4a4a4a;
      strong {
        color: #2c3e50;
      }

      &.paragraph1 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 28px;
      }

      &.paragraph2 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }

    .info {
      &-details {
        border-left: 4px solid ${colors.bg1};
        padding-left: 16px;
        border-radius: 4px;
      }
    }

    .venue {
      padding: 28px 0;
    }

    .additional-info {
      padding-bottom: 24px;
      border-bottom: 2px solid ${colors.border1};
    }

    ul {
      padding: 24px 0 12px;
      li {
        border-radius: 8px;
        box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
        padding: 8px 16px;
        font-size: 16px;
        color: ${colors.text3};
        line-height: 24px;
        margin-bottom: 12px;
      }
    }

    a {
      color: ${colors.bg2};
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 768px) {
    .main-wrapper {
      padding: 20px 15px;
    }

    .olympiad-stages {
      .stage {
        padding: 20px;
      }
    }
  }

  @media (max-width: 480px) {
    .olympiad-stages .stage p {
      font-size: 1rem;
    }
  }
`;
