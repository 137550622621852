import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ModalVideo from "react-modal-video";

import Button from "./shared/Button.jsx";

import { useLanguageDetector } from "../hooks/useLanguageDetector.js";

import { Styles } from "./styles/aboutUs.js";

import Data from "../data/about-us/about-us.json";
import DataRu from "../data/about-us/about-us-rus.json";
import DataUz from "../data/about-us/about-us-uz.json";

const HomeAboutUs = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [data] = useLanguageDetector(Data, DataRu, DataUz);

  const openModal = () => {
    setIsOpen(true);
  };
  return (
    <Styles>
      <section className="about-us">
        <Container>
          <Row>
            <Col md="6">
              <div className="about-image">
                <img
                  src={`../assets/images/${data?.mainImage}`}
                  className="main-img"
                  alt=""
                />
                <img
                  src={"../assets/images/pattern.webp"}
                  className="pattern-img"
                  alt=""
                />
                <div
                  className="video-player"
                  style={{
                    backgroundImage: `url(../assets/images/${data?.videoBackground})`,
                  }}
                >
                  <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId={data?.videoId}
                    onClose={() => setIsOpen(false)}
                  />
                  <button onClick={openModal} className="play-button">
                    <i className="las la-play"></i>
                  </button>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="about-content">
                <h4 className="about-title">{data?.title}</h4>
                <p className="about-para">{data?.desc1}</p>
                <Button
                  className="readmore-btn"
                  text={"Read More"}
                  onClick={() => navigate("/about")}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default HomeAboutUs;
