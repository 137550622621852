import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import remove from "lodash/remove";
import { v4 as uuidv4 } from "uuid";

import FormWithInputAndLabel from "../../components/shared/FormWithInputAndLabel";
import EmploymentInputsComponent from "../../components/apply/EmploymentInputsComponent";
import Button from "../../components/shared/Button";
import EducationInputsComponent from "../../components/apply/EducationInputsComponent";
import LanguageInputsComponent from "../../components/apply/LanguageInputsComponent";
import RefereeInputsComponent from "../../components/apply/RefereeInputsComponent";
import AlertComponent from "../../components/shared/AlertComponent";

import {
  CURRENCY_ENUM,
  DURATION_ENUM,
  LANGUAGE_LEVELS,
} from "../../utilities/constants";
import { useStatusContext } from "../../hooks/contextHooks/useStatusContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import userService from "../../services/userService";
import {
  cleanUpObject,
  getObjectPropertyName,
} from "../../utilities/objectUtils";

import { Styles } from "./styles/account.js";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import FileDropDownComponent from "../../components/shared/FileDropDownComponent";
import TooltipInformationComponent from "../../components/shared/TooltipInformationComponent";
import { appendToFormData } from "../../utilities/formDataUtils";
import moment from "moment";
import vacancyData from "../../data/vacancy/vacancy.json";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addYears } from "date-fns";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("First name field is required"),
  lastName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Last name field is required"),
  dateOfBirth: yup
    .date()
    .max(addYears(new Date(), -16), "You must be at least 16 years old.")
    .required("Date of birth field is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email field is required"),
  phoneNumber: yup.string().optional(),
  country: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Country field is required"),
  city: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("City field is required"),
  district: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("District field is required"),
  street: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Street field is required"),
  instruction: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Home number field is required"),
  postalCode: yup.number().required("Postal code field is required"),
  salary: yup.number().required("Salary field is required"),
  qualifiedForPosition: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("The field is required to fill"),
  workForPTU: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("The field is required to fill"),
  visionOfPosition: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("The field is required to fill"),
  confirmation: yup.boolean().required(),
});

const defaultValues = {
  employmentPeriods: [{ id: uuidv4() }],
  educations: [{ id: uuidv4() }],
  trainings: [{ id: uuidv4() }],
  languages: [{ id: uuidv4() }],
  referees: [{ id: uuidv4() }],
};

const EmployeeApplicationForm = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const [onFetch, isLoading] = useBeManualFetcher();
  const formRef = useRef();
  const { id } = useParams();
  const { onErrorStatus } = useStatusContext();
  const [currency, setCurrency] = useState(CURRENCY_ENUM.UZS);
  const [files, setFiles] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [passportCopy, setPassportCopy] = useState([]);

  const onFormSubmit = async (form) => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      email,
      phoneNumber,
      hearAboutUs,
      salary,
      workForPTU,
      qualifiedForPosition,
      visionOfPosition,
      confirmation,
      country,
      city,
      district,
      street,
      postalCode,
      instruction,
      employmentPeriods,
      educations,
      practicalTrainings,
      languages,
      referees,
    } = form;

    let requestBody = {
      firstName,
      lastName,
      dateOfBirth: new Date(dateOfBirth).toISOString(),
      email,
      phoneNumber,
      hearAboutVacancy: hearAboutUs,
      expectedSalary: {
        amount: salary,
        currency,
      },
      positionType: vacancyData.find((vacancy) => vacancy.id == id).jobTitle,
      comment: {
        reasonAboutWorkingForPtu: workForPTU,
        positionOfQualified: qualifiedForPosition,
        visionOfPosition,
      },
      country,
      city,
      district,
      street,
      postalCode,
      instruction,
      employmentPeriods:
        employmentPeriods?.length > 0
          ? employmentPeriods.map((employment) => {
              return {
                ...employment,
                startDate: new Date(employment?.startDate).toISOString(),
                endDate:
                  employment?.isPresent == false
                    ? new Date(employment?.endDate).toISOString()
                    : undefined,
              };
            })
          : [],
      educations:
        educations?.length > 0
          ? educations.map((education) => {
              return {
                ...education,
                startDate: new Date(education?.startDate).toISOString(),
                endDate:
                  education?.isPresent === false
                    ? new Date(education?.endDate).toISOString()
                    : undefined,
              };
            })
          : [],
      practicalTrainings:
        practicalTrainings?.length > 0
          ? practicalTrainings.map((training) => {
              return {
                ...training,
                startDate: new Date(training?.startDate).toISOString(),
                endDate:
                  training?.isPresent === false
                    ? new Date(training?.endDate).toISOString()
                    : undefined,
              };
            })
          : [],
      languages:
        languages?.length > 0
          ? languages.map((language) => {
              return {
                ...language,
                languageLevel: getObjectPropertyName(
                  LANGUAGE_LEVELS,
                  language?.level
                ),
              };
            })
          : [],
      referees: referees?.length > 0 ? referees : [],
    };

    if (confirmation) {
      return await onFetch({
        action: async () => await userService.createEmployee(requestBody),
        onLoad: async (response) => {
          if (response?.id) {
            let newFormData = new FormData();

            appendToFormData(newFormData, {
              employeeId: response?.id,
              passport: passportCopy?.[0],
              photo: photo?.[0],
            });

            if (files?.length > 0) {
              files.forEach((file) => {
                newFormData.append(`files`, file);
              });
            }

            await userService.uploadEmployeeFiles(newFormData).then(() => {
              setFiles([]);
              setPhoto([]);
              setPassportCopy([]);
              AlertComponent({
                title:
                  "Thank you for your application and interest to work for PTU!",
                text: "We will consider your application and inform you regarding further steps of selection within 14 working days. ",
                icon: "success",
              });
              formRef.current.reset();
            });
          }
        },
      });
    }

    return onErrorStatus({
      title: "Validation Error",
      message:
        "You should check the checkbox to confirm that all provided were true and correct",
    });
  };

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <BreadcrumbBox
          title={"Job Application Form"}
          image="/assets/images/banner-6.jpg"
        />
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>Apply Now</h3>
                    <div className="registration-note">
                      <strong>Note:</strong> Please, fill all required input
                      fields to proceed. Required fields marked with * sign.
                    </div>
                  </div>
                  <form
                    id="form_registration"
                    className="form"
                    onSubmit={handleSubmit(onFormSubmit)}
                    ref={formRef}
                  >
                    <Row>
                      <Col md="6">
                        <FormWithInputAndLabel
                          label={"First Name"}
                          type="text"
                          placeholder="First name"
                          name="firstName"
                          id="firstName"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="6">
                        <FormWithInputAndLabel
                          label={"Last Name"}
                          type="text"
                          placeholder="Last name"
                          name="lastName"
                          id="lastName"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <FormWithInputAndLabel
                          label={"Date of Birth"}
                          type="date"
                          name="dateOfBirth"
                          id="dateOfBirth"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <FormWithInputAndLabel
                          label={"Email Address"}
                          type="email"
                          placeholder={"Email address"}
                          name="email"
                          id="email"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <FormWithInputAndLabel
                          label={"Phone Number"}
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          defaultValue={"+998"}
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={"How did you hear about this vacancy?"}
                          type="text"
                          placeholder={
                            "Write shortly how did you hear about us"
                          }
                          name="hearAboutUs"
                          id="hearAboutUs"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3 mt-3">
                          Address<strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Country"}
                          type="text"
                          placeholder="Country"
                          name="country"
                          id="country"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"City"}
                          type="text"
                          placeholder="City"
                          name="city"
                          id="city"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"District"}
                          type="text"
                          placeholder="District"
                          name="district"
                          id="district"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Street"}
                          type="text"
                          placeholder="Street"
                          name="street"
                          id="street"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Home Number"}
                          type="text"
                          placeholder="Home Number"
                          name="instruction"
                          id="instruction"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Postal Code"}
                          type="number"
                          placeholder="Postal Code"
                          name="postalCode"
                          id="postalCode"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3 mt-3">
                          Employment<strong className="text-danger">*</strong>
                        </h6>
                      </Col>

                      <EmploymentInputsComponent
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                      />
                      <Col md="12">
                        <h6 className="strategy-title mb-3">
                          Education
                          <strong className="text-danger">*</strong>
                        </h6>
                      </Col>

                      <EducationInputsComponent
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        name="educations"
                        isRequired={true}
                      />
                      <Col md="12">
                        <h6 className="strategy-title mb-3">
                          Practical Training
                        </h6>
                      </Col>

                      <EducationInputsComponent
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        name="practicalTrainings"
                        isRequired={false}
                      />
                      <Col md="12">
                        <h6 className="strategy-title mb-3">
                          Languages
                          <strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      <LanguageInputsComponent
                        register={register}
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                      />
                      <Col md="12">
                        <h6 className="strategy-title mb-3 mt-3">
                          Referees
                          <strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      <RefereeInputsComponent
                        register={register}
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                      />
                      <Col md="6" lg="6">
                        <div className="form-control">
                          <label htmlFor={"passportCopy"}>
                            Scan version of your passport
                            <strong className="text-danger">*</strong>
                          </label>
                          <FileDropDownComponent
                            files={passportCopy}
                            setFiles={setPassportCopy}
                            buttonText="Select File"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-control">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor={"photo"}>
                              Personal photo (3.5x4.5)
                              <strong className="text-danger">*</strong>
                            </label>
                            <TooltipInformationComponent
                              componentToShow={
                                <div className="photo-information">
                                  <p className="mb-2">
                                    Official photo with white background is
                                    acceptable.
                                  </p>
                                </div>
                              }
                            />
                          </div>
                          <FileDropDownComponent
                            files={photo}
                            setFiles={setPhoto}
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="form-control">
                          <label htmlFor={"id"}>
                            Scan version of your diplomas (Bachelors/Masters
                            diploma)* with transcript
                            <strong className="text-danger">*</strong>
                          </label>
                          <FileDropDownComponent
                            files={files}
                            setFiles={setFiles}
                            multiple
                            buttonText={"Select files"}
                          />
                        </div>
                      </Col>
                      <Col md="8">
                        <FormWithInputAndLabel
                          label={"Expected Salary"}
                          type="number"
                          name="salary"
                          id="salary"
                          min={0}
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <div className="form-control">
                          <label htmlFor="currency">{"Currency"}</label>
                          <select
                            id="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            {Object.values(CURRENCY_ENUM).map((currency) => (
                              <option value={currency} key={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={"Why do you want to work for PTU?"}
                          name="workForPTU"
                          id="workForPTU"
                          register={register}
                          errors={errors}
                          isTextArea
                          required
                        />
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={
                            "Why do you think you are qualified for this position?"
                          }
                          name="qualifiedForPosition"
                          id="qualifiedForPosition"
                          register={register}
                          errors={errors}
                          isTextArea
                          required
                        />
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={"What is your vision of this position?"}
                          name="visionOfPosition"
                          id="visionOfPosition"
                          register={register}
                          errors={errors}
                          isTextArea
                          required
                        />
                      </Col>
                      <Col md="12">
                        <div className={`custom-checkbox mb-4`}>
                          <label
                            htmlFor={"confirmation"}
                            className="font-weight-bold"
                          >
                            I hereby declare that the information provided is
                            true and correct
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="checkbox"
                            name="confirmation"
                            id="confirmation"
                            className="ml-2"
                            style={{ width: "20px" }}
                            {...register("confirmation", {
                              required: "Input field is required to fill*",
                            })}
                          />
                          {errors?.["confirmation"]?.type === "required" && (
                            <p className="invalid">
                              {errors?.["confirmation"]?.message}
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center mt-2">
                      <Button
                        text={"Submit"}
                        className="px-5 py-3"
                        type={"submit"}
                        isLoading={isLoading}
                        eventName={"employee_app_submit"}
                      />
                      <Button
                        text={"Reset"}
                        className="px-5 py-3"
                        onClick={() => formRef.current.reset()}
                        isLoading={isLoading}
                        eventName={"employee_app_submit"}
                      />
                    </div>
                  </form>
                  <div className="have_account-btn text-center">
                    <p>
                      Are you a student?{" "}
                      <Link to="/apply-students">Apply here</Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default EmployeeApplicationForm;
