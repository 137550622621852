import styled from "styled-components";
import { colors } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  h4.news-title {
    color: #444444;
    font-weight: 600;
    padding-bottom: 10px;
    margin-bottom: 20px;
    position: relative;

    :before {
      position: absolute;
      content: "";
      background: #662a30;
      width: 50px;
      height: 2px;
      bottom: 0;
      left: 0;

      @media (max-width: 767px) {
        width: 30px;
      }
    }

    @media (max-width: 767px) {
      font-size: 16px;
      padding-bottom: 7.5px;
    }
  }

  .latest-news-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    .latest-news-taller {
      max-width: 45%;
      height: 500px;
      flex: 0 45%;
      position: relative;
      background: ${colors.border1};
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 8px;
      transition: 200ms;
      cursor: pointer;

      @media (max-width: 767px) {
        max-width: 100%;
        flex: 0 100%;
        height: 250px;
        margin-bottom: 15px;
      }
    }

    .latest-news-wider {
      max-width: 55%;
      flex: 0 55%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 15px;

      .latest-news-wider-rectangle {
        border-radius: 8px;
        background: ${colors.border1};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 50%;
        position: relative;
        transition: 150ms;
        cursor: pointer;

        @media (max-width: 767px) {
          height: 250px;
        }
      }

      @media (max-width: 767px) {
        max-width: 100%;
        flex: 0 100%;
        flex-direction: column;
      }
    }

    .latest-news-wider-squares-wrapper {
      height: 50%;
      display: flex;
      justify-content: space-between;
      column-gap: 15px;

      .latest-news-wider-square {
        height: 100%;
        border-radius: 8px;
        background: ${colors.border1};
        background-size: cover;
        width: 100%;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        transition: 150ms;
        cursor: pointer;
      }

      @media (max-width: 767px) {
        row-gap: 15px;
        flex-wrap: wrap;
        height: 265px;
      }
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-bottom: 265px;
    }
  }

  .news-category-wrapper {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    column-gap: 5px;

    .news-title-wrapper {
      border-radius: 24px;
      padding: 5px 10px;
      color: ${colors.black1};
      background-color: ${colors.text6};

      h5 {
        color: ${colors.bg1};
        font-size: 14px;
        z-index: 20;
        font-weight: 600;

        @media (max-width: 767px) {
          font-size: 11px;
        }
      }

      @media (max-width: 767px) {
        right: 12px;
        top: 12px;
        padding: 3.5px 7px;
      }
    }
  }

  .bottom-gradient {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .article {
      position: absolute;
      bottom: 0;
      padding-bottom: 15px;
      padding-left: 20px;
      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
        max-width: 90%;
        color: ${colors.text6};
      }

      @media (max-width: 1000px) {
        padding-left: 17px;
      }
    }
  }

  .view-more-button-wrapper {
    margin-bottom: 30px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 20px;

    i {
      color: ${colors.bg1};
      font-size: 16px;
    }
  }
`;
