import React from "react";
import HeaderDropdown from "./HeaderDropdown";
import HeaderItem from "./HeaderItem";
import { ConferencePageHeadings, StudentsPageHeadings } from "../configs";

export const HeaderLinks = () => {
  return (
    <>
      <div className="menu-box d-flex justify-content-end">
        <ul className="nav menu-nav">
          <HeaderItem title={"Study"} link="/study" />
          <HeaderItem title={"Apply"} link="/apply-students" />
          <HeaderItem
            title={"Students"}
            link="/student-life"
            dropdownHeadings={StudentsPageHeadings}
            logoAreaData={{
              bgImg: "/assets/images/download.jpg",
              title:
                "Navigating to: Student Life, Support, Wellbeing, and Regulations",
              subtitle: "PTU Students",
              paragraph: "Our students are at the heart of everything we do",
            }}
          />
          <HeaderItem title={"News"} link="/news" />
          <HeaderItem title={"Olympiad"} link="/olympiad" />
          {/* <HeaderItem
            title={"Conference"}
            link="/conference"
            dropdownHeadings={ConferencePageHeadings}
            logoAreaData={{
              bgImg: "/assets/images/download.jpg",
              title:
                "PTU Inaugural International Conference on Pharmaceutics and Life Sciences",
              subtitle: "ICPL 2024",
              paragraph: "Frontiers of research and higher education",
            }}
          /> */}
          <HeaderDropdown
            title={"About Us"}
            link="/about"
            navItems={[
              { title: "About Us", link: "/about" },
              { title: "Our Team", link: "/team" },
              { title: "Strategy", link: "/strategy" },
              { title: "Partners", link: "/partners" },
              { title: "Contact Us", link: "/contact" },
              { title: "Vacancies", link: "/vacancies" },
            ]}
            dropdownRightAlign={true}
          />
        </ul>
      </div>
      <div className="d-none"></div>
    </>
  );
};
