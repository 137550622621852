import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .hero-slider-area {
    top: -1px;
    position: relative;

    .swiper {
      position: relative;

      .swiper-slide {
        position: relative;

        .image-container {
          display: block;
          overflow: hidden;
          position: relative;
          max-height: 800px;

          .mask {
            -webkit-mask-image: url(/assets/images/hero-mask.png);
            mask-image: url(/assets/images/hero-mask.png);
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            -webkit-mask-position: center;

            .slider-image {
              height: 82vh;
              width: 100%;
              transition: transform 6s linear;
              transform: scale(1);
              object-fit: cover;
              filter: brightness(50%);
            }
          }

          @media (max-width: 767px) {
            height: 550px;
          }

          @media (max-width: 575px) {
            height: 460px;
          }
        }

        .slider-table {
          width: 100vw;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9999;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .slider-tablecell {
            .slider-box {
              display: flex;
              flex-direction: column;
              justify-content: end;

              .slider-title {
                margin-bottom: 12px;
                p {
                  font-size: 20px;
                  color: ${colors.bg2};
                  font-weight: 600;

                  @media (max-width: 1199px) {
                    font-size: 16px;
                  }

                  @media (max-width: 767px) {
                    font-size: 14px;
                  }
                }
              }

              .slider-desc {
                margin-bottom: 32px;
                h1 {
                  font-size: 32px;
                  color: #ffffff;
                  max-width: 800px;
                  font-weight: 600;
                  line-height: 1.3;

                  @media (max-width: 1199px) {
                    font-size: 24px;
                  }

                  @media (max-width: 767px) {
                    font-size: 16px;
                  }
                }
              }

              .slider-btn {
                display: flex;
                justify-content: end;
                column-gap: 20px;

                @media (max-width: 575px) {
                  align-items: center !important;
                }
              }

              .slider-btn-left-aligned {
                display: flex;
                justify-content: start;
                column-gap: 20px;

                @media (max-width: 575px) {
                  justify-content: center !important;
                }
              }

              @media (max-width: 575px) {
                text-align: center !important;
              }
            }

            .slider-box2 {
              .slider-desc {
                h1 {
                  margin-left: auto;
                }
              }
            }

            .text-right {
              text-align: right !important;
            }
          }
        }
      }

      .swiper-slide-active {
        .image-container {
          img.slider-image {
            transform: scale(1.15);
          }
        }

        .slider-table {
          .slider-tablecell {
            .slider-title {
              animation: fadeInDown 1s 0.2s both;
            }

            .slider-desc {
              animation: fadeInUp 1.5s 0.2s both;
            }

            .slider-btn {
              animation: fadeInUp 2s 0.2s both;
            }
          }
        }
      }
    }

    .swiper-btn {
      position: absolute;
      top: 85%;
      left: 40px;
      font-size: 24px;
      color: ${colors.border1};
      text-align: center;
      padding: 10px 12px;
      border-radius: 8px;
      transition: all 0.3s ease;
      margin-top: -35px;
      z-index: 111;

      &:hover {
        background: ${colors.gr_bg};
        color: #ffffff;
      }
    }

    .swiper-btn.slider-button-next {
      left: inherit;
      right: 40px;
    }

    @media (max-width: 999px) {
      margin: 0;
    }
  }
`;
