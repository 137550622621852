import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

import { DURATION_ENUM } from "../../utilities/constants";

const EmploymentInputsComponent = ({
  register,
  control,
  errors,
  watch,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "employmentPeriods",
  });

  return (
    <>
      <Col md="12">
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Col md="5">
                <FormWithInputAndLabel
                  type="date"
                  label={"Start Date"}
                  name={`employmentPeriods[${index}].startDate`}
                  register={register}
                  error={errors?.employmentPeriods?.[index]?.startDate}
                  required
                />
              </Col>
              <Col md="7">
                <Row>
                  <Controller
                    name={`employmentPeriods[${index}].isPresent`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {!value && (
                          <Col lg="8">
                            <FormWithInputAndLabel
                              name={`employmentPeriods[${index}].endDate`}
                              label={"End Date"}
                              type="date"
                              control={control}
                              register={register}
                              errors={errors}
                              error={
                                errors?.employmentPeriods?.[index]?.endDate
                              }
                              required={!value}
                            />
                          </Col>
                        )}
                        <Col lg="4">
                          {value && (
                            <div className="form-control mb-2">
                              <label>End Date</label>
                            </div>
                          )}
                          <div
                            className={`${value ? "" : "mt-5"} custom-checkbox`}
                          >
                            <input
                              type="checkbox"
                              id={`present-${index}`}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                setValue(
                                  `employmentPeriods[${index}].endDate`,
                                  ""
                                );
                                setValue(
                                  `employmentPeriods[${index}].durationOfEmploymentPeriod`,
                                  e.target.checked
                                    ? DURATION_ENUM.TILL_NOW
                                    : DURATION_ENUM.FINISHED
                                );
                              }}
                            />
                            <label htmlFor={`present-${index}`}>Present</label>
                          </div>
                        </Col>
                      </>
                    )}
                  />
                </Row>
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Name of Organization"}
                  type="text"
                  placeholder={"Name of Organization"}
                  name={`employmentPeriods[${index}].organizationName`}
                  register={register}
                  errors={errors}
                  error={errors?.employmentPeriods?.[index]?.organizationName}
                  required
                />
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Position in Organization"}
                  type="text"
                  placeholder={"Position"}
                  name={`employmentPeriods[${index}].positionInOrganization`}
                  register={register}
                  errors={errors}
                  error={
                    errors?.employmentPeriods?.[index]?.positionInOrganization
                  }
                  required
                />
              </Col>
              <Col md="12">
                <FormWithInputAndLabel
                  label={
                    "Summary of the main duties and responsibilities in the Organization"
                  }
                  type="text"
                  placeholder={
                    "Enter shortly about main duties and responsibilities"
                  }
                  name={`employmentPeriods[${index}].summaryAboutResponsibilities`}
                  register={register}
                  errors={errors}
                  error={
                    errors?.employmentPeriods?.[index]
                      ?.summaryAboutResponsibilities
                  }
                  required
                />
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Name of the Supervisor"}
                  type="text"
                  placeholder={"Name of the Supervisor"}
                  name={`employmentPeriods[${index}].supervisorPhoneNumber.name`}
                  register={register}
                  errors={errors}
                  error={
                    errors?.employmentPeriods?.[index]?.supervisorPhoneNumber
                      ?.name
                  }
                  required
                />
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Contact Number/ Email address of Supervisor"}
                  type="text"
                  placeholder={"Contact Number or Email address"}
                  name={`employmentPeriods[${index}].supervisorPhoneNumber.phoneNumber`}
                  register={register}
                  errors={errors}
                  error={
                    errors?.employmentPeriods?.[index]?.supervisorPhoneNumber
                      ?.phoneNumber
                  }
                  required
                />
              </Col>
              {fields.length > 1 && (
                <Col md="12" className="d-flex justify-content-end">
                  <Button
                    text={"Remove"}
                    iconName={"ph:trash"}
                    onClick={() => remove(index)}
                  />
                </Col>
              )}
            </Row>
          );
        })}
      </Col>
      <Col md="12" className="d-flex justify-content-end">
        <Button
          text={"Add a row"}
          className="outline"
          iconName={"ph:plus-bold"}
          onClick={() => append({ id: uuidv4() })}
        />
      </Col>
    </>
  );
};

export default EmploymentInputsComponent;
