import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import CourseItemList from "./components/CourseItemsList";
import HelmetComponent from "./../../components/helmet/HelmetComponent";

import { Styles } from "./styles/course.js";

const CourseList = () => {
  return (
    <div className="main-wrapper course-page">
      <HelmetComponent title="Study" description={""} keywords={""} />
      <BreadcrumbBox title="Study" image="/assets/images/banner-2.jpg" />
      <Styles>
        <section className="course-list-area">
          <Container>
            <Row>
              <Col lg="12" md="12" sm="12">
                <div className="course-items2">
                  <Row>
                    <CourseItemList />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default CourseList;
