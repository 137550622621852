// exports will be kept in alphebetic order
export const BE_ERROR_MESSAGES = {
  1000: "Something went wrong. Please, try again later or contact us about the corresponding issue",
  1001: "Address not found",
  1003: "Age must be greater than 16",
  1004: "User not found",
  1008: "Authentication error",
  1009: "User authentication failed",
  1010: "You're not authenticated, please signin/signup",
  1012: "Not authorized to access this resource",
  1013: "Email already exists",
  1014: "Not authorized to access this resource",
  1015: "Sorry, the news you trying to access has been deleted",
  1017: "Invalid passport number",
  1019: "Age should be greater than 14",
  1021: "Email field is required",
  1022: "At least one Pre University course should be selected",
};

export const CURRENCY_ENUM = {
  UZS: "UZS",
  USD: "USD",
};

export const DATE_FORMATS = {
  DATE_REGISTERED: "DD.MM.YYYY",
  DATE_TIME: "MMMM Do YYYY, h:mm:ss",
  DATE_TIME_FORMAL: "YYYY-MM-DDTHH:mm",
  DATE_WITHOUT_YEAR: "MMMM DD, h:mm:ss",
  DATE_WITHOUT_DAY: "YYYY-MM",
  DATE_ONLY: "YYYY-MM-DD",
  TIME: "hh:mm",
  DAY: "dddd",
};

export const DURATION_ENUM = {
  FINISHED: "FINISHED",
  TILL_NOW: "TILL_NOW",
};

export const FACULTIES_ENUM = {
  FOUNDATION_CERTIFICATE_PHARMACEUTICAL_SCIENCE:
    "FOUNDATION_CERTIFICATE_PHARMACEUTICAL_SCIENCE",
  FOUNDATION_CERTIFICATE_BIOSCIENCES: "FOUNDATION_CERTIFICATE_BIOSCIENCES",
  FOUNDATION_CERTIFICATE_SCIENCES: "FOUNDATION_CERTIFICATE_SCIENCES",
  MSCI_PHARMACY: "MSCI_PHARMACY",
};

export const FETCH_STATE = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  COMPLETE: "complete",
};

export const IMAGE_KEYS = {
  PRODUCT_IMAGE_GET: "size1500x1500",
  COVER_IMAGE_GET: "size1920x1080",
  LOGO_IMAGE_GET: "size500x500",
  COURIER_IMAGE_GET: "size1000x1000",
  COVER_IMAGE: "1920x1080",
  LOGO_IMAGE: "500x500",
  PRODUCT_IMAGE: "1500x1500",
  COURIER_IMAGE: "1000x1000",
  PRODUCT_IMAGE_MINIMUM_SIZE: 1500,
  LOGO_IMAGE_SIZE: 500,
  COVER_IMAGE_WIDTH_SIZE: 1920,
  COVER_IMAGE_HEIGHT_SIZE: 1080,
  COURIER_IMAGE_MINIMUM_SIZE: 1000,
};

export const LANGUAGE_KEYS = {
  UZ: "uzUZ",
  EN: "enUS",
  RU: "ruRU",
};

export const COURSE_ENUM = {
  PRE_UNIVERSITY: "PRE_UNIVERSITY",
  PRE_FOUNDATION: "PRE_FOUNDATION",
};

export const PRE_UNIVERSITY_COURSES = {
  GENERAL_ENGLISH: "GENERAL_ENGLISH",
  IELTS_PREPARATION: "IELTS_PREPARATION",
  GENERAL_CHEMISTRY: "GENERAL_CHEMISTRY",
  BIOLOGY: "BIOLOGY",
};

export const PRE_UNIVERSITY_COURSES_READABLE = {
  GENERAL_ENGLISH: "General English",
  IELTS_PREPARATION: "IELTS Preparation",
  GENERAL_CHEMISTRY: "General Chemistry",
  BIOLOGY: "Biology",
};

export const LANGUAGE_LEVELS = {
  ELEMENTARY: "Elementary Proficiency",
  LIMITED_WORKING: "Limited Working Proficiency",
  PROFESSIONAL: "Professional Working Proficiency",
  FULL_PROFESSIONAL: "Full Professional Proficiency",
  NATIVE: "Native or Bilingual Proficiency",
};

export const SCHOLARSHIP_TYPES_ENUM = {
  TUITION_FEE: "Tuition fee based",
  SCHOLARSHIP: "Scholarship",
};

export const REGIONS = {
  TASHKENT: "TASHKENT",
  TASHKENT_REGION: "TASHKENT_REGION",
  SAMARQAND: "SAMARQAND",
  BUKHARA: "BUKHARA",
  ANDIJAN: "ANDIJAN",
  FERGANA: "FERGANA",
  JIZZAX: "JIZZAX",
  NAMANGAN: "NAMANGAN",
  NAVOIY: "NAVOIY",
  QASHQADARYO: "QASHQADARYO",
  SURXONDARYO: "SURXONDARYO",
  XORAZM: "XORAZM",
  SIRDARYO: "SIRDARYO",
  REPUBLIC_OF_KARAKALPAKSTAN: "REPUBLIC_OF_KARAKALPAKSTAN",
};


export const REGIONS_READABLE = {
  TASHKENT: "Tashkent",
  TASHKENT_REGION: "Tashkent Region",
  SAMARQAND: "Samarqand",
  BUKHARA: "Buxoro",
  ANDIJAN: "Andijan",
  FERGANA: "Fergana",
  JIZZAX: "Jizzax",
  NAMANGAN: "Namangan",
  NAVOIY: "Navoi",
  QASHQADARYO: "Qashqadaryo",
  SURXONDARYO: "Surxondaryo",
  XORAZM: "Xorazm",
  SIRDARYO: "Sirdaryo",
  REPUBLIC_OF_KARAKALPAKSTAN: "Republic of Karakalpakstan",
};
