import React from "react";
import { Col, Row } from "react-bootstrap";

import Heading from "../../../components/shared/Heading";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";
import { useTranslation } from "react-i18next";

const CourseDetailsStructureTab = ({
  data,
  postTitle,
  title = "Structure",
}) => {
  const { t } = useTranslation();
  let lastSemester = null;
  return (
    <section className="instructor-tab" id="structure">
      <Heading tag="h5" title={title} />
      {data.map((module, index) => (
        <>
          {module?.semester && module.semester !== lastSemester && (
            <p className="course-postTitle mb-4">
              {t("Semester")} {module.semester}
            </p>
          )}
          {/* Update lastSemester after checking */}
          {(() => {
            lastSemester = module?.semester;
            return null;
          })()}
          <div className="instructor-item" key={index}>
            <Row>
              <Col md="12">
                <div className="instructor-content">
                  <div className="instructor-box">
                    <div className="top-content d-flex justify-content-between flex-wrap">
                      <div className="instructor-name d-flex align-items-center">
                        <h6>{module?.title}</h6>
                        {module?.credits ? (
                          <div className="course-structure-details mb-0">
                            <p className="mb-0">
                              {module?.credits} {t("credits")}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="instructor-desk">
                      <ul className="list-unstyled">
                        {typeof module?.description === "string" ? (
                          <p>{module?.description}</p>
                        ) : (
                          <>
                            {module?.postTitle ? (
                              <li>
                                <p>
                                  <strong>{module?.postTitle}</strong>
                                </p>
                              </li>
                            ) : null}
                            {module?.description?.map((desc, index) => (
                              <li key={index}>
                                <div>
                                  <Icon
                                    icon="ph:paper-plane-right-duotone"
                                    fontSize={24}
                                    className="pt-1"
                                    color={colors.bg1}
                                  />
                                </div>
                                <p>{desc}</p>
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ))}
    </section>
  );
};

export default CourseDetailsStructureTab;
