import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles";
import EnglishData from "../../data/student-support-service-contact-us/data.json";
import RussianData from "../../data/student-support-service-contact-us/data-ru.json";
import UzbekData from "../../data/student-support-service-contact-us/data-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { colors } from "../../components/common/element/elements";
import { Icon } from "@iconify/react";
import Heading from "./../../components/shared/Heading";

const StudentSupportServiceContactUs = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper student-support-service-page">
      <BreadcrumbBox
        title="Student Support Service - Contact Us"
        image="/assets/images/student-1.webp"
      />
      <Styles>
        <section className="student-support-service-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="student-support-service-content">
                  <h6>{data?.section1?.subtitle1}</h6>
                  <ul className="list-unstyled">
                    {data?.section1?.contact_emails?.map((item, index) => (
                      <li className="d-flex mb-3 item" key={index}>
                        <div>
                          <Icon
                            icon="ph:paper-plane-right-duotone"
                            fontSize={20}
                            color={colors.bg2}
                          />
                        </div>
                        <p>
                          <span className="pr-3">{item?.title}</span>-
                          <a href={`mailto:${item?.email}`} className="pl-3">
                            {item?.email}
                          </a>
                        </p>
                      </li>
                    ))}
                  </ul>
                  <p className="pb-3">{data?.section1?.subtitle2}</p>
                  <p className="pb-3">{data?.section1?.subtitle3}</p>
                  <p className="pb-3">
                    <b>{data?.section1?.subtitle4?.bold}</b>{" "}
                    {data?.section1?.subtitle4?.normal}
                  </p>
                  <p className="pb-5">
                    {data?.section1?.subtitle5?.title}{" "}
                    <a href={`mailto:${data?.section1?.subtitle5?.email}`}>
                      {data?.section1?.subtitle5?.email}
                    </a>
                  </p>
                  <Heading
                    tag="h4"
                    className="strategy-title"
                    title={data?.section2?.title}
                  />
                  <p className="pb-3">{data?.section2?.subtitle1}</p>
                  <p>
                    <i>{data?.section2?.opening_hours?.title}</i>
                  </p>
                  <div className="pb-3">
                    {data?.section2?.opening_hours?.items?.map(
                      (item, index) => (
                        <p key={index}>{item}</p>
                      )
                    )}
                  </div>
                  <p className="pb-3">{data?.section2?.subtitle2}</p>
                  <p className="pb-3">{data?.section2?.subtitle3}</p>
                  <h6 className="pb-3">
                    {data?.section2?.contact_rules?.title}
                  </h6>
                  <div className="pb-3">
                    {data?.section2?.contact_rules?.items?.map(
                      (item, index) => (
                        <p className="pl-5 pb-3" key={index}>
                          * <span className="pl-3">{item}</span>
                        </p>
                      )
                    )}
                  </div>
                  <div className="pb-3">
                    <b>{data?.section2?.subtitle4.title}</b>
                    <span>{data?.section2?.subtitle4?.content}</span>
                  </div>
                  <p className="pb-3">
                    {data?.section2?.subtitle5?.prelink}{" "}
                    <a href={`mailto:${data?.section2?.subtitle5?.link}`}>
                      {data?.section2?.subtitle5?.link}
                    </a>
                    {data?.section2?.subtitle5?.postlink}
                  </p>
                  <p className="pb-3">{data?.section2?.subtitle6}</p>
                  <div className="pb-3">
                    <b>{data?.section2?.subtitle7?.title}</b>
                    <span>{data?.section2?.subtitle7?.content}</span>
                  </div>
                  <p className="pb-3">{data?.section2?.subtitle8}</p>
                  <p className="pb-3">
                    {data?.section2?.subtitle9?.prelink}{" "}
                    <a href={`mailto:${data?.section2?.subtitle9?.link}`}>
                      {data?.section2?.subtitle9?.link}
                    </a>
                    {data?.section2?.subtitle9?.postlink}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default StudentSupportServiceContactUs;
