import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import Button from "../components/shared/Button";
import { useLanguageDetector } from "../hooks/useLanguageDetector";
import { Styles } from "./styles/heroSlider.js";

import Data from "../data/hero/hero-slider.json";
import DataRu from "../data/hero/hero-slider-ru.json";
import DataUz from "../data/hero/hero-slider-uz.json";

const HeroSlider = () => {
  const navigate = useNavigate();
  const [sliderData] = useLanguageDetector(Data, DataRu, DataUz);

  return (
    <Styles>
      <section className="hero-slider-area">
        <Swiper
          slidesPerView={1}
          slidesPerGroup={1}
          loop={sliderData?.length > 1}
          speed={1000}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          spaceBetween={0}
          effect="fade"
          navigation={{
            nextEl: ".slider-button-next",
            prevEl: ".slider-button-prev",
          }}
          modules={[Navigation, Autoplay, EffectFade]}
        >
          {sliderData?.map((data, i) => (
            <SwiperSlide key={i}>
              {data?.backgroundImagePosition && (
                <div className="slider-item">
                  <div className="image-container">
                    <div className="mask">
                      <img
                        className="slider-image"
                        src={`/assets/images/${data.backgroundImage}`}
                        alt="Pharmaceutical Technical University"
                        loading="lazy"
                        style={{
                          objectPosition: data?.backgroundImagePosition,
                          filter:
                            i === 2 ? "brightness(100%)" : "brightness(50%)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="slider-table">
                    <div className="slider-tablecell">
                      <Container>
                        <Row>
                          <Col md="12">
                            <div className={data.uniqClass}>
                              <div className="slider-title">
                                <p>{data.title}</p>
                              </div>
                              <div className="slider-desc">
                                <h1>{data.desc}</h1>
                                {data.desc2 && <h1>{data.desc2}</h1>}
                              </div>
                              <div className={data.btnClass}>
                                <Button
                                  text={i === 2 ? "Learn more" : "Find more"}
                                  onClick={() => navigate(data.btnOneLink)}
                                />
                                <Button
                                  text={i === 2 ? "Contact Us" : "Register Now"}
                                  style={{
                                    display: i === 1 ? "none" : "inline-block",
                                  }}
                                  onClick={() => navigate(data.btnTwoLink)}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider-button-prev swiper-btn">
          <i className="flaticon-arrow-left-th"></i>
        </div>
        <div className="slider-button-next swiper-btn">
          <i className="flaticon-arrow-right-th"></i>
        </div>
      </section>
    </Styles>
  );
};

export default HeroSlider;
