import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

import { DURATION_ENUM } from "../../utilities/constants";
import { Controller, useFieldArray } from "react-hook-form";

const EducationInputsComponent = ({
  register,
  control,
  errors,
  setValue,
  name,
  isRequired
}) => {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: name,
  });

  return (
    <>
      <Col md="12">
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Col md="5">
                <FormWithInputAndLabel
                  label={"Start Date"}
                  type="date"
                  name={`${name}[${index}].startDate`}
                  register={register}
                  error={errors?.[name]?.[index]?.startDate}
                  required={isRequired}
                />
              </Col>
              <Col md="7">
                <Row>
                  <Controller
                    name={`${name}[${index}].isPresent`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <>
                        {!value && (
                          <Col lg="8">
                            <FormWithInputAndLabel
                              name={`${name}[${index}].endDate`}
                              label={"End Date"}
                              type="date"
                              control={control}
                              register={register}
                              errors={errors}
                              error={errors?.[name]?.[index]?.endDate}
                              required={!value}
                            />
                          </Col>
                        )}
                        <Col lg="4">
                          {value && (
                            <div className="form-control mb-2">
                              <label>End Date</label>
                            </div>
                          )}
                          <div
                            className={`${value ? "" : "mt-5"} custom-checkbox`}
                          >
                            <input
                              type="checkbox"
                              id={`present-${index}`}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                                setValue(`${name}[${index}].endDate`, "");
                                setValue(
                                  `${name}[${index}].${isRequired ? "durationOfEducation" : "durationOfPracticalTraining"}`,
                                  e.target.checked
                                    ? DURATION_ENUM.TILL_NOW
                                    : DURATION_ENUM.FINISHED
                                );
                              }}
                            />
                            <label htmlFor={`present-${index}`}>Present</label>
                          </div>
                        </Col>
                      </>
                    )}
                  />
                </Row>
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Name of Institution, place and country"}
                  type="text"
                  placeholder={"Name of Institution"}
                  name={`${name}[${index}].institution`}
                  register={register}
                  error={errors?.[name]?.[index]?.institution}
                  required={isRequired}
                />
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Qualification"}
                  type="text"
                  placeholder={"Qualification"}
                  name={`${name}[${index}].qualification`}
                  register={register}
                  error={errors?.[name]?.[index]?.qualification}
                  required={isRequired}
                />
              </Col>
              {fields.length > 1 && (
                <Col md="12" className="d-flex justify-content-end">
                  <Button
                    text={"Remove"}
                    iconName={"ph:trash"}
                    onClick={() => remove(index)}
                  />
                </Col>
              )}
            </Row>
          );
        })}
      </Col>
      <Col md="12" className="d-flex justify-content-end">
        <Button
          text={"Add a row"}
          className="outline"
          iconName={"ph:plus-bold"}
          onClick={() => append({ id: uuidv4() })}
        />
      </Col>
    </>
  );
};

export default EducationInputsComponent;
