import React from "react";
import { useNavigate } from "react-router-dom";
import { Styles } from "./styles.js";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Button from "../../components/shared/Button.jsx";
import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";
import { useLanguageDetector } from "../../hooks/useLanguageDetector.js";
import EnglishData from "../../data/opendays/opendays.json";
import RussianData from "../../data/opendays/opendays-ru.json";
import UzbekData from "../../data/opendays/opendays-uz.json";
import Heading from "../../components/shared/Heading.jsx";
import { Container } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements.js";

const OpenDays = () => {
  const navigate = useNavigate();
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  const handleEventClick = (info) => {
    const selectedDate = info.event.startStr;
    navigate(`/opendays/form?date=${selectedDate}`);
  };

  return (
    <Styles>
      <BreadcrumbBox
        title={data.pageTitle}
        image="/assets/images/opendays-1.webp"
        preTitle={data.preTitle}
        centerTitle={true}
        registerButton={true}
        registerLink="/opendays/form"
      />
      <Container>
        <section className="opendays-section">
          <div className="pb-5">
            <Heading
              tag="h4"
              className="strategy-title"
              title={data.calendar.title}
            />
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              events={data.calendar.list}
              height="auto"
              eventClick={handleEventClick}
            />
          </div>
          <Heading
            tag="h4"
            className="strategy-title"
            title={data.expect.title}
          />
          <ul className="list-unstyled">
            {data?.expect?.list.map((item, index) => (
              <li className="d-flex mb-3 item" key={index}>
                <div>
                  <Icon
                    icon="ph:paper-plane-right-duotone"
                    fontSize={20}
                    color={colors.bg2}
                  />
                </div>
                <p className="ps-3">{item}</p>
              </li>
            ))}
          </ul>
          <div className="py-5 d-flex justify-content-center">
            <Button
              className={"apply-button"}
              text={"Register now"}
              onClick={() => navigate("/opendays/form")}
            ></Button>
          </div>
        </section>
      </Container>
    </Styles>
  );
};

export default OpenDays;
