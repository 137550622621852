import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";
import { useFieldArray } from "react-hook-form";

const RefereeInputsComponent = ({
  register,
  control,
  errors,
  
}) => {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "referees",
  });

  return (
    <>
      <Col md="12">
        {fields.map((field, index) => {
          return (
            <Row key={field.id}>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Name and Surname"}
                  type="text"
                  placeholder={"Name and Surname"}
                  name={`referees[${index}].name`}
                  register={register}
                  error={errors?.referees?.[index]?.name}
                  required={index === 0}
                />
              </Col>
              <Col md="6">
                <FormWithInputAndLabel
                  label={"Organization Name"}
                  type="text"
                  placeholder={"Organization Name"}
                  name={`referees[${index}].organization`}
                  register={register}
                  error={errors?.referees?.[index]?.organization}
                  required={index === 0}
                />
              </Col>
              <Col md="4">
                <FormWithInputAndLabel
                  label={"Position in Organization"}
                  type="text"
                  placeholder={"Position"}
                  name={`referees[${index}].position`}
                  register={register}
                  error={errors?.referees?.[index]?.position}
                  required={index === 0}
                />
              </Col>
              <Col md="4">
                <FormWithInputAndLabel
                  label={"Contact telephone numbers"}
                  type="text"
                  placeholder={"Contact telephone numbers"}
                  name={`referees[${index}].phoneNumber`}
                  register={register}
                  error={errors?.referees?.[index]?.phoneNumber}
                  required={index === 0}
                />
              </Col>
              <Col md="4">
                <FormWithInputAndLabel
                  label={"Email"}
                  type="email"
                  placeholder={"Email"}
                  name={`referees[${index}].email`}
                  register={register}
                  error={errors?.referees?.[index]?.email}
                  required={index === 0}
                />
              </Col>
              {fields.length > 1 &&  (
                <Col md="12" className="d-flex justify-content-end">
                  <Button
                    text={"Remove"}
                    iconName={"ph:trash"}
                    onClick={() => remove(index)}
                  />
                </Col>
              )}
            </Row>
          );
        })}
      </Col>
      <Col md="12" className="d-flex justify-content-end">
        <Button
          text={"Add a row"}
          className="outline"
          iconName={"ph:plus-bold"}
          onClick={() => append({ id: uuidv4() })}
        />
      </Col>
    </>
  );
};

export default RefereeInputsComponent;
