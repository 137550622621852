import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import LanguageDropdownComponent from "../shared/LanguageDropdownComponent.jsx";
import MobileMenuNavigationItem from "../shared/MobileMenu/MobileMenuNavigationitem.jsx";
import { NavigationWithSubmenu } from "../shared/MobileMenu/NavgationWithSubmenu.jsx";

import { Styles } from "./styles/mobileMenu.js";

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => setIsMenuOpen(true);

  const handleMenuClose = () => setIsMenuOpen(false);

  return (
    <Styles>
      <section
        className="mobile-menu-area"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/background-texture.webp)`,
        }}
      >
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-topbar d-flex justify-content-between">
                <div className="topbar-item">
                  <p>
                    <i className="las la-phone"></i>
                    <a href="tel:+998559000500">+998 (55) 900-05-00</a>
                  </p>
                </div>
              </div>
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button" onClick={handleMenuOpen}>
                    <i className="las la-bars"></i>
                  </div>
                  <div className="mb-logo">
                    <Link to={"/"}>
                      <img src={"/assets/images/logo-white.webp"} alt="" />
                    </Link>
                  </div>
                </div>
                <LanguageDropdownComponent />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`mb-sidebar ${isMenuOpen ? "opened" : ""}`}>
        <div
          className="mb-sidebar-heading d-flex justify-content-between"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/background-texture.webp)`,
          }}
        >
          <h5>Menu</h5>
          <div className="close-mb-sidebar" onClick={handleMenuClose}>
            <i className="las la-times"></i>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <MobileMenuNavigationItem
            title={"Home"}
            link="/"
            onClick={handleMenuClose}
          />
          <MobileMenuNavigationItem
            title={"Study"}
            link="/study"
            onClick={handleMenuClose}
          />
          <MobileMenuNavigationItem
            title={"Apply"}
            link="/apply-students"
            onClick={handleMenuClose}
          />
          <NavigationWithSubmenu
            title={"Students"}
            onClick={handleMenuClose}
            submenuLinks={[
              { title: "Student Life", link: "/student-life" },
              { title: "Accommodation", link: "/accommodation" },
              {
                title: "Tuition Fees",
                link: "/tuition-fees",
              },
              {
                title: "Scholarship",
                link: "/scholarship",
              },
              {
                title: "Student Support Service",
                link: "/student-support-service",
              },
              {
                title: "Student Support Service - Contact Us",
                link: "/student-support-service-contact-us",
              },
              {
                title: "Student Information and Support Section – PTU Assists",
                link: "/student-information-support",
              },
              { title: "International", link: "/international" },
              {
                title: "Students Union",
                link: "/students-union",
              },
              {
                title: "Student Voice",
                link: "/student-voice",
              },
              { title: "Wellbeing", link: "/wellbeing" },
              { title: "Social Engagement - Clubs", link: "/social" },
              {
                title: "Academic Regulations",
                link: "/academic-regulations",
              },
            ]}
          />
          <MobileMenuNavigationItem
            title={"News"}
            link="/news"
            onClick={handleMenuClose}
          />
          <MobileMenuNavigationItem
            title={"Olympiad"}
            link="/olympiad"
            onClick={handleMenuClose}
          />
          {/* <MobileMenuNavigationItem
            title={"Conference"}
            link="/conference"
            onClick={handleMenuClose}
          /> */}
          <NavigationWithSubmenu
            title={"About Us"}
            link="/about"
            onClick={handleMenuClose}
            submenuLinks={[
              { title: "Strategy", link: "/strategy" },
              { title: "Partners", link: "/partners" },
              { title: "Our Team", link: "/team" },
              { title: "Contact Us", link: "/contact" },
              { title: "Vacancies", link: "/vacancies" },
            ]}
          />
        </div>
      </section>
      <div
        className={`mb-sidebar-overlay ${isMenuOpen ? "visible" : ""}`}
        onClick={handleMenuClose}
      ></div>
    </Styles>
  );
};

export default MobileMenu;
