import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";

export const registerOpendays = async (body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.registerOpendays,
  };

  const apiRegisterOpendays = await backendHttpRequest({
    resourceObject,
    body,
  });

  return apiRegisterOpendays;
};
