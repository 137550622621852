import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import Button from "./shared/Button.jsx";

import { useLanguageDetector } from "../hooks/useLanguageDetector.js";

import { Styles } from "./styles/courseFilter.js";

import Data from "../data/course/filter.json";
import DataRu from "../data/course/filter-rus.json";
import DataUz from "../data/course/filter-uz.json";
import { Icon } from "@iconify/react";
import { colors } from "./common/element/elements.js";

function CourseFilter() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [courseData] = useLanguageDetector(Data, DataRu, DataUz);

  useEffect(() => {
    const buttons = document.querySelector(".filter-btn-list").children;
    const items = document.querySelector(".filter-items").children;

    items[items?.length - 1].style.display = "none";
    items[items?.length - 2].style.display = "none";

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener("click", function () {
        for (let j = 0; j < buttons.length; j++) {
          buttons[j].classList.remove("active");
        }

        this.classList.add("active");
        const target = this.getAttribute("data-target");

        for (let k = 0; k < items.length; k++) {
          items[k].style.display = "none";

          if (items[k].getAttribute("data-id") === target) {
            items[k].style.display = "block";
          }

          if (target === courseData?.courseTabbar?.[0] && k < 3) {
            items[k].style.display = "block";
          }
        }
      });
    }
  });

  return (
    <Styles>
      {/* Course Area */}
      <section className="course-filter">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{courseData?.secTitle}</h4>
              </div>
            </Col>
            <Col md="12">
              <div className="filter-btns">
                <ul className="filter-btn-list list-unstyled list inline">
                  {courseData?.courseTabbar?.map((tabbarItem, index) => (
                    <li
                      key={tabbarItem}
                      data-target={tabbarItem}
                      className={`${
                        index === 0 ? "active" : ""
                      } list-inline-item"`}
                    >
                      {tabbarItem}
                    </li>
                  ))}
                </ul>
              </div>
              <Row className="filter-items">
                {courseData?.dataList?.map((data, i) => (
                  <Col xl="4" lg="6" key={i} data-id={data.targetId}>
                    <motion.div
                      className="course-item"
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 1 }}
                    >
                      <Link
                        to={{
                          pathname: `/programme-details/${data.id}`,
                          state: data,
                        }}
                      >
                        <div
                          className="course-image"
                          style={{
                            backgroundImage: `url(../assets/images/${data.imgUrl})`,
                          }}
                        ></div>
                        <div className="course-content">
                          <div className="course-description">
                            <h6 className="heading">
                              <Link
                                to={{
                                  pathname: `/programme-details/${data.id}`,
                                  state: data,
                                }}
                              >
                                <span> {data?.courseTitle} </span>
                                {data?.courseSubtitle}
                                <span> {data?.courseTitle2} </span>
                              </Link>
                            </h6>
                            <p className="desc">{data.courseDesc}</p>
                          </div>
                          <div className="course-face">
                            <div className="duration mb-2">
                              <p>
                                <Icon
                                  icon="ph:calendar-duotone"
                                  fontSize={20}
                                  className="mr-1"
                                  color={colors.bg2}
                                />
                                {t("Start Date")}:{" "}
                                <strong>{data?.startDate}</strong>
                              </p>
                            </div>
                            <div className="duration mb-2">
                              <p>
                                <Icon
                                  icon="ph:clock-duotone"
                                  fontSize={20}
                                  className="mr-1"
                                  color={colors.bg2}
                                />
                                {t("Duration")}:{" "}
                                <strong>{data?.duration}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </motion.div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md="12" className="text-center">
              <div className="viewall-btn">
                <Button
                  className={"px-5"}
                  text={"View all programmes"}
                  onClick={() => navigate("/study")}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default CourseFilter;
