import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import ScrollToTop from "./helper/ScrollToTop";
import Layout from "./layout/Layout";
import StatusContextProvider from "./contextProviders/StatusContextProvider";
import NewsPageContextProvider from "./newsPage/contexts/newsContext";
import { GlobalStyle } from "./components/common/styles/global.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App = () => {
  return (
    <HelmetProvider>
      <StatusContextProvider>
        <Router>
          <GlobalStyle />
          <ScrollToTop />
          <NewsPageContextProvider>
            <Routes>
              <Route path="*" element={<Layout />}></Route>
            </Routes>
          </NewsPageContextProvider>
        </Router>
      </StatusContextProvider>
    </HelmetProvider>
  );
};

export default App;
