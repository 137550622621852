import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { motion } from "framer-motion";

import Button from "../../../components/shared/Button";

import { useLanguageDetector } from "../../../hooks/useLanguageDetector";

import EnglishData from "../../../data/course/filter.json";
import RussianData from "../../../data/course/filter-rus.json";
import UzbekData from "../../../data/course/filter-uz.json";

const CourseItemList = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const navigate = useNavigate();

  const handleNavigate = (path, state) => navigate(path, { state: state });

  return (
    <Fragment>
      {data?.dataList?.map((data, i) => (
        <Col md="12" key={i}>
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="course-item d-flex"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${data.imgUrl}`}
              alt="Course img"
              className="course-image"
            />
            <div className="course-content">
              <div>
                <h6 className="heading">
                  <Link
                    to={`/programme-details/${data?.id}`}
                    state={data}
                  >
                    <span> {data?.courseTitle} </span>
                    {data?.courseSubtitle}
                    <span> {data?.courseTitle2} </span>
                  </Link>
                </h6>
                <p className="desc">{data.courseDesc}</p>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  text={"View Details"}
                  className="details-btn"
                  onClick={() =>
                    handleNavigate(`/programme-details/${data.id}`, data)
                  }
                />
              </div>
            </div>
          </motion.div>
        </Col>
      ))}
    </Fragment>
  );
};

export default CourseItemList;
