import React from "react";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import { Styles } from "./styles/anti-corruption";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../components/shared/Heading";
import { SampleDocument } from "../courses/components/CourseDetailSampleExamTab";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "../../data/anti-corruption/anti-corruption.json";
import RussianData from "../../data/anti-corruption/anti-corruption-ru.json";
import UzbekData from "../../data/anti-corruption/anti-corruption-uz.json";

const AntiCorruption = () => {
  const [antiCorruptionData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper anti-corruption-page">
      <BreadcrumbBox
        title="Anti-corruption"
        image="/assets/images/anti-corruption-1.jpg"
      />
      <Styles>
        <section className="anti-corruption">
          <Container>
            <Row>
              <Col xs="12">
                <div className="anti-corruption-content">
                  <Heading
                    tag="h4"
                    className="strategy-title"
                    title={"Anti-corruption documents"}
                  />
                  {antiCorruptionData?.data?.map((file, index) => (
                    <SampleDocument
                      key={index}
                      title={file.title}
                      url={file.url}
                      buttonText="View"
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default AntiCorruption;
