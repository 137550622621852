import React from "react";
import { Helmet } from "react-helmet-async";

const defaultKeywords = `
  Pharmaceutical Technical University, pharmacy education Uzbekistan, MSc pharmacy programs, BSc biochemistry, 
  BSc biosciences, pharmaceutical research Uzbekistan, top pharmacy universities, biosciences degree Uzbekistan, 
  advanced pharmaceutical studies, technical pharmacy education, international pharmaceutical university, 
  pharmacology studies, student-centric learning, bioscience innovation, STEM education Uzbekistan.
  Фармацевтический технический университет, фармацевтическое образование Узбекистан, магистратура фармации, 
  бакалавриат биохимии, бакалавриат бионаук, фармацевтические исследования Узбекистан, лучшие университеты фармации, 
  степень бионауки Узбекистан, фармацевтические технологии, международный фармацевтический университет, 
  фармакологическое обучение, студентоориентированное образование, инновации в бионауках, STEM-образование Узбекистан.
  Farmatsevtika texnik universiteti, O‘zbekistonda farmatsevtika taʼlimi, farmatsiya magistratura dasturi, biokimyo bakalavriati, 
  biofanlar bakalavriati, O‘zbekistonda farmatsevtika tadqiqotlari, eng yaxshi farmatsiya universitetlari, 
  O‘zbekistonda biofanlar darajasi, farmatsevtika texnologiyalari, xalqaro farmatsevtika universiteti, farmakologiya taʼlimi, 
  talabaga yo‘naltirilgan o‘qitish, biofanlar innovatsiyalari, STEM taʼlimi O‘zbekiston.
`;

const defaultDescription = `
  Pharmaceutical Technical University (PharmatechUni.uz) is a premier institution in Uzbekistan offering world-class programs in MSc Pharmacy, 
  BSc Biochemistry, and BSc Biosciences. Explore cutting-edge pharmaceutical education, advanced research opportunities, 
  and a vibrant student community at Uzbekistan's leading technical university.
  Фармацевтический технический университет (PharmatechUni.uz) – ведущий вуз Узбекистана, предлагающий программы магистратуры по 
  фармации, бакалавриата по биохимии и бионаукам. Получите доступ к передовому фармацевтическому образованию, современным научным 
  исследованиям и активному студенческому сообществу в главном техническом университете Узбекистана.
  Farmatsevtika texnik universiteti (PharmatechUni.uz) – O‘zbekistonda yetakchi oliy o‘quv yurti bo‘lib, farmatsiya magistratura 
  dasturi, biokimyo va biofanlar bakalavriatlarini taklif etadi. Zamonaviy farmatsevtika taʼlimi, ilg‘or tadqiqot imkoniyatlari va 
  faol talabalar jamoasidan bahramand bo‘ling.
`;

const HelmetComponent = ({
  title,
  description,
  keywords,
  canonical = window.location.href,
  image = "/logo_icon.png",
  type = "website",
  site_name = "Pharmaceutical Technical University",
}) => {
  const metaTitle = `${title ? `${title} | ` : ""}${site_name}`;
  const metaDescription = `${description || ""}, ${defaultDescription}`;
  const metaKeywords = `${keywords || ""}, ${defaultKeywords}`;

  return (
    <Helmet>
      {/* Base SEO */}
      <html lang="en" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <link rel="canonical" href={canonical} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />

      {/* Open Graph Metadata */}
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={site_name} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonical} />

      {/* Twitter Card Metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@pharmatechuni" />

      {/* Structured Data - JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": type === "article" ? "Article" : "WebPage",
          name: metaTitle,
          description: metaDescription,
          url: canonical,
          image: image,
          publisher: {
            "@type": "Organization",
            name: site_name,
            logo: {
              "@type": "ImageObject",
              url: "/logo_icon.png",
            },
          },
        })}
      </script>
    </Helmet>
  );
};

export default HelmetComponent;
