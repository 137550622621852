import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Heading from "../../components/shared/Heading";

import "../strategy/Strategy.css";

const CampusMap = () => {
  return (
    <div className="main-wrapper strategy-page">
      <BreadcrumbBox title="Our Strategy" />
      <section className="strategy-area">
        <Container>
          <Row>
            <Col md="12">
              <div>
                <Heading
                  className="strategy-title"
                  tag="h4"
                  title="Campus Map"
                />
              </div>
              <div className="strategy-image-wrapper">
                <img
                  className="strategy-image"
                  src={`/assets/images/strategy.webp`}
                  alt="startegy"
                  width={"100%"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CampusMap;
