import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Container, Row, Col } from "react-bootstrap";
import Datas from "../data/partner/partner.json";
import { Styles } from "./styles/teamSlider.js";
import Heading from "./shared/Heading";

const PartnersSlider = () => {
  return (
    <Styles>
      <section className="team-member-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <Heading tag="h4" title={Datas?.sliderTitle} />
              </div>
            </Col>
            <Col md="12" className="team-slider">
              <Swiper
                modules={[Autoplay]}
                slidesPerView={5}
                loop={true}
                speed={1000}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                spaceBetween={50}
                breakpoints={{
                  0: { slidesPerView: 1 },
                  576: { slidesPerView: 2 },
                  768: { slidesPerView: 2 },
                  992: { slidesPerView: 5 },
                }}
              >
                {Datas.dataList.map((data, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={data.partnerLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="team-item"
                    >
                      <img
                        src={`/assets/images/${data.partnerImage}`}
                        alt={data.partnerName}
                        className="img-fluid"
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default PartnersSlider;
