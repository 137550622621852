import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const ScholarshipWrapper = styled.section`
  padding: 30px 0;

  margin: 0 auto;
`;

export const ScholarshipSection = styled.div`
  margin-bottom: 40px;

  h5 {
    margin-bottom: 20px;
    font-weight: 600;
    color: ${colors.black1};
    font-size: 24px;
  }

  p {
    color: ${colors.text2};
    line-height: 1.8;
    font-size: 16px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    &.fw-bold {
      font-weight: 600;
      color: ${colors.black2};
    }
  }

  .sample-item {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-radius: 8px;
    transition: all 0.2s ease;
    overflow: hidden;
    margin-bottom: 15px;
    padding: 15px 20px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 10%);

    .sample-title {
      font-weight: 600;
      color: ${colors.black1};

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    .download-button {
      font-size: 14px;
      color: #fff;
      border: none;
      background: ${colors.gr_bg};
      text-align: center;
      border-radius: 8px;
      font-size: 14px;
      padding: 4px 20px;
      cursor: pointer;

      &:hover {
        background: ${colors.gr_bg2};
        text-decoration: none;
      }

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
`;

export const ScholarshipStep = styled.div`
  background: ${colors.white};
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 20px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  }

  .icon-wrapper {
    margin-right: 15px;
    min-width: 24px;
    display: flex;
    align-items: flex-start;
    padding-top: 4px;
  }

  h6 {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.black1};
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
    color: ${colors.text3};
  }
`;

export const ScholarshipLink = styled.a`
  color: ${colors.bg1};
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  display: inline-block;

  &:hover {
    color: ${colors.bg2};
    text-decoration: underline;
  }
`;

export const DatesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 10px;
    color: ${colors.text2};
    font-size: 15px;
    position: relative;
    padding-left: 20px;

    &:before {
      content: "•";
      position: absolute;
      left: 0;
      color: ${colors.bg1};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;