import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .new-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    padding: 130px;
    text-align: center;
    background-color: #f0d6aa;

    h2 {
      font-size: 50px;
      margin-bottom: 10px;
      color: ${colors.black1};
      font-weight: 700;
    }

    h3 {
      font-size: 35px;
      color: #181818;
      font-weight: 600;
    }

    .apply-button {
      font-size: 24px;
      padding: 20px 40px;
      background-color: ${colors.bg1};

      p {
        font-size: 20px;
      }
    }
  }

  .banner-container {
    margin-bottom: -140px;
    z-index: 2;
    position: relative;

    @media (max-width: 768px) {
      margin-bottom: -100px;
    }

    .banner {
      position: relative;
      height: 100%;
      width: 100%;
      background-color: #f5e8d2;
      background: ${colors.border1};
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      transition: 200ms;
      padding: 80px 100px;
      border-radius: 12px;

      @media (max-width: 768px) {
        padding: 40px 60px;
      }

      .bottom-gradient {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0)
        );
        transform: none;
        border-radius: 8px;
      }

      div {
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 992px) {
            flex-direction: column;
            align-items: start;
            row-gap: 20px;
          }

          h2 {
            font-weight: 700;
            max-width: 500px;
            color: ${colors.text6};

            @media (max-width: 992px) {
              font-size: 24px;
            }

            @media (max-width: 768px) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .banner-container + footer.footer2 {
    /* padding: 190px 0 30px; */
    background-color: #181818;
  }

  footer.footer2 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: #202020;
    padding: 70px 0 30px;

    .footer-logo-info {
      img {
        max-width: 171px;
        margin-bottom: 16px;

        @media (max-width: 767px) {
          margin-bottom: 18px;
        }
      }

      p {
        font-size: 14px;
        color: ${colors.text6};
        line-height: 24px;
        padding-bottom: 12px;
        margin-bottom: 12px;

        @media (max-width: 991px) {
          line-height: 25px;
          margin-bottom: 12px;
        }
      }

      ul {
        li {
          display: flex;
          /* align-items: center; */
          color: ${colors.text6};
          margin-bottom: 12px;

          i {
            font-size: 18px;
            color: ${colors.bg2};
            width: 35px;
            vertical-align: top;

            @media (max-width: 991px) {
              width: 20px;
            }
          }

          .phone-numbers {
            display: flex;
            flex-wrap: wrap;
            column-gap: 12px;
          }

          a {
            color: ${colors.text6};
            &:hover {
              color: ${colors.bg2};
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: 1199px) {
        margin-bottom: 30px;
      }
    }

    .f-links {
      padding-left: 25px;

      h5 {
        color: ${colors.bg2};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 35px;

        @media (max-width: 991px) {
          font-size: 18px;
          margin-bottom: 15px;
        }

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      div {
        ul {
          li {
            a {
              font-size: 14px;
              color: ${colors.text6};
              line-height: 39px;

              i {
                font-size: 12px;
                color: ${colors.bg2};
                margin-right: 10px;

                @media (max-width: 991px) {
                  margin-right: 5px;
                }
              }

              &:hover {
                color: ${colors.bg2};
              }
            }
          }
        }
      }

      @media (max-width: 1199px) {
        margin-bottom: 30px;
      }

      @media (max-width: 991px) {
        padding-left: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .f-newsletter {
      h5 {
        color: ${colors.border1};
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 38px;

        @media (max-width: 992px) {
          font-size: 18px;
          margin-bottom: 20px;
        }

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 14px;
        color: ${colors.text3};
        line-height: 25px;
        margin-bottom: 22px;
      }

      form.form {
        p.form-control {
          padding: 0;
          width: auto;
          height: auto;
          background: transparent;
          border: none;
          margin-bottom: 20px;
          position: relative;

          input {
            width: 100%;
            height: 40px;
            background: rgba(255, 255, 255, 0.08);
            color: ${colors.border1};
            padding-left: 15px;
            border: none;
            border-radius: 8px;
            margin-bottom: 5px;

            &::placeholder {
              font-style: italic;
              color: ${colors.text3};
            }
          }

          span {
            font-size: 13px;
            color: ${colors.bg2};
            font-weight: 300;
          }
        }

        p.form-control.success {
          input {
            border: 2px solid ${colors.bg2};
          }

          &::before {
            position: absolute;
            content: "\f058";
            font-family: "Line Awesome Free";
            font-size: 24px;
            color: ${colors.green};
            font-weight: 900;
            top: 8px;
            right: 10px;
          }
        }

        p.form-control.error {
          input {
            border: 2px solid ${colors.red};
          }

          &::before {
            position: absolute;
            content: "\f06a";
            font-family: "Line Awesome Free";
            font-size: 24px;
            color: ${colors.red};
            font-weight: 900;
            top: 8px;
            right: 10px;
          }
        }

        p.form-control.error {
          span {
            visibility: visible;
          }
        }

        .submit-form {
          background: ${colors.text6};
          width: 100%;
          height: 40px;
          border: none;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          p {
            color: ${colors.bg1};
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 0;
          }

          &:hover {
            background: ${colors.gr_bg2};
          }
        }
      }
    }
  }

  .copyright-area {
    background-color: #181818;
    padding: 45px 0;

    .copyright-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 14px;
        color: ${colors.text6};

        i {
          color: ${colors.bg2};
          margin: 0 2px;
        }

        a {
          color: ${colors.bg2};

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;
      text-align: start;
    }
  }
`;
