import React from "react";

import Heading from "../../../components/shared/Heading";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";

const CouresDetailsOverviewTab = ({ data }) => {
  return (
    <section className="overview-tab" id="overview">
      <div className="course-desc">
        <Heading tag="h5" title={"Overview"} />
        <p>{data?.courseDescription}</p>
      </div>
      <div>
        <Heading tag="h5" title={data?.whyChoose?.title} />
        <div className="why-choose-list">
          {data?.whyChoose?.list?.map((item) => (
            <div className="overview-card">
              <div className={`icon-wrapper ${item?.color}`}>
                <Icon icon={item?.icon} fontSize={30} color={colors.text6} />
              </div>
              <h6>{item?.title}</h6>
              <p>{item?.description}</p>
            </div>
          ))}
        </div>
      </div>

      {data?.nextSteps?.title && (
        <div className="next-steps">
          <Heading tag="h5" title={data?.nextSteps?.title} />
          {data?.nextSteps?.pargraphs?.map((item) => (
            <div className="d-flex align-items-center">
              <p className="mb-2 mr-2">
                {item?.text}{" "}
                {item?.link ? (
                  <a href={item?.link?.url} className="mb-2">
                    {item?.link?.text}
                  </a>
                ) : null}
              </p>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default CouresDetailsOverviewTab;
