import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithInputAndLabel from "../../components/shared/FormWithInputAndLabel.jsx";
import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";

import { useBeManualFetcher } from "../../hooks/useBeManualFetcher.js";
import { REGIONS, REGIONS_READABLE } from "../../utilities/constants.js";
import { cleanUpObject } from "../../utilities/objectUtils.js";
import userService from "../../services/userService.js";

import { Styles } from "../apply/styles/apply.js";
import { useTranslation } from "react-i18next";
import Button from "../../components/shared/Button.jsx";
import AlertComponent from "../../components/shared/AlertComponent.js";
import olympiadService from "../../services/olympiadService.js";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("First name field is required"),
  lastName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Last name field is required"),
  middleName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Middle name field is required"),
  region: yup
    .string()
    .oneOf(Object.keys(REGIONS), "Invalid region")
    .required("Region field is required"),
  city: yup.string().required("City field is required"),
  school: yup.string().required("School field is required"),
  yearOfStudy: yup.string().required("Year of study field is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email field is required"),
  phoneNumber: yup
    .string()
    .length(13, "Phone number must be exactly 12 numbers long")
    .required("Phone number field is required"),
  confirmation: yup
    .boolean()
    .oneOf([true], "You must agree to the terms and conditions"),
});

const OlympiadApplicationForm = () => {
  const { isFormActive, setIsFormActive } = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const [onFetch, isLoading] = useBeManualFetcher();
  const formRef = useRef();
  const { t } = useTranslation();
  const [region, setRegion] = useState("");

  const onFormSubmit = async (form) => {
    const {
      firstName,
      lastName,
      middleName,
      email,
      phoneNumber,
      region,
      city,
      school,
      yearOfStudy,
      ieltsScore,
      secondPhoneNumber,
      socialMediaPermission,
    } = form;

    const filteredObject = cleanUpObject({
      firstName,
      lastName,
      middleName,
      email,
      phoneNumber,
      region,
      city,
      school,
      yearOfStudy,
      ieltsScore,
      secondPhoneNumber,
      socialMediaPermission,
    });

    return await onFetch({
      action: () => olympiadService.createOlympiadParticipant(filteredObject),
      onLoad: () => {
        formRef.current.reset();

        AlertComponent({
          title: "You have successfully submitted your olumpiad application!",
          text: "Our Admission team will send you e-mail as soon as possible.",
          icon: "success",
        });
      },
    });
  };

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <BreadcrumbBox
          title={t("Olympiad Application Form")}
          image="/assets/images/banner-5.jpg"
        />
        <section className="registration-area application-form">
          <Container>
            {isFormActive ? (
              <Row>
                <Col md="12">
                  <div className="registration-box">
                    <div className="registration-title text-center">
                      <h3>{t("Olympiad Application Form")}</h3>
                      <div className="registration-note">
                        {t(
                          "If you have any question, please feel free to contact us through Telegram bot?"
                        )}{" "}
                        <a href="https://t.me/ptuuz_bot" target="_blank">
                          {t("Click Here")}
                        </a>
                      </div>
                    </div>
                    <form
                      id="form_registration"
                      className="form"
                      onSubmit={handleSubmit(onFormSubmit)}
                      ref={formRef}
                    >
                      <Row>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"First Name"}
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            id="firstName"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"Last Name"}
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            id="lastName"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"Middle Name"}
                            type="text"
                            placeholder="Middle Name"
                            name="middleName"
                            id="middleName"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="6">
                          <div className="form-control mb-4">
                            <label htmlFor="region">
                              {t("Region")}
                              <strong className="text-danger">*</strong>
                            </label>
                            <select
                              placeholder={t("Select region...")}
                              className="select h-40px"
                              name="region"
                              {...register("region")}
                            >
                              {Object.values(REGIONS).map((region) => (
                                <option value={region} key={region}>
                                  {t(REGIONS_READABLE?.[region])}
                                </option>
                              ))}
                            </select>
                            {errors?.["region"] && (
                              <p className="invalid">
                                {errors?.["region"]?.message}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col md="6" lg="6">
                          <FormWithInputAndLabel
                            label={"District / City"}
                            type="text"
                            placeholder="District / City"
                            name="city"
                            id="city"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"School Number / Academic Lyceum"}
                            type="text"
                            placeholder="School Number / Academic Lyceum"
                            name="school"
                            id="school"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"Year of Study"}
                            type="text"
                            placeholder="Year of Study"
                            name="yearOfStudy"
                            id="yearOfStudy"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"IELTS Score, if exists"}
                            type="text"
                            placeholder="IELTS Score"
                            name="ieltsScore"
                            id="ieltsScore"
                            register={register}
                            errors={errors}
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"Email Address"}
                            type="email"
                            placeholder="Email Address"
                            name="email"
                            id="email"
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"Phone Number"}
                            type="text"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            id="phoneNumber"
                            defaultValue={"+998"}
                            register={register}
                            errors={errors}
                            required
                          />
                        </Col>
                        <Col md="4" lg="4">
                          <FormWithInputAndLabel
                            label={"Second Phone Number"}
                            type="text"
                            placeholder="Second Phone Number"
                            name="secondPhoneNumber"
                            id="secondPhoneNumber"
                            defaultValue={"+998"}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                        <Col md="12">
                          <div className={`custom-checkbox cursor-pointer`}>
                            <input
                              type="checkbox"
                              name="socialMediaPermission"
                              id="socialMediaPermission"
                              style={{
                                width: "20px",
                              }}
                              {...register("socialMediaPermission")}
                            />
                            <label htmlFor={"socialMediaPermission"}>
                              {t(
                                "Would you like to join our social media community for exclusive updates and important announcements?"
                              )}
                              <strong className="text-danger">*</strong>
                            </label>
                            {errors?.["socialMediaPermission"] && (
                              <p className="invalid">
                                {errors?.["socialMediaPermission"]?.message}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className={`custom-checkbox pt-2 mb-4`}>
                            <input
                              type="checkbox"
                              name="confirmation"
                              id="confirmation"
                              style={{
                                width: "20px",
                              }}
                              {...register("confirmation", {
                                required: "Input field is required to fill*",
                              })}
                            />
                            <label htmlFor={"confirmation"} className="mr-2">
                              {t(
                                "I consent to process my personal data for the admission purpose."
                              )}
                              <strong className="text-danger">*</strong>
                            </label>
                            {errors?.["confirmation"] && (
                              <p className="invalid">
                                {errors?.["confirmation"]?.message}
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-center">
                          <Button
                            type={"submit"}
                            className="px-5 mt-3"
                            text={"Submit"}
                            disabled={isLoading}
                            isLoading={isLoading}
                            eventName={"student_app_submit"}
                          />
                        </Col>
                      </Row>
                    </form>
                    {/* <div className="have_account-btn text-center">
                    <p>
                      {t("Do you looking for a job vacancies?")}{" "}
                      <Link to="/apply-employees">{t("Apply here")}</Link>
                    </p>
                  </div> */}
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="registration-box">
                <h3>{t("Olympiad Application Form")}</h3>
                <p>{t("The application period has ended.")}</p>
              </div>
            )}
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default OlympiadApplicationForm;
