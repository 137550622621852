import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";

export const createOlympiadParticipant = async (body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.createOlympiadParticipant,
  };

  const apiOlympiadParticipant = await backendHttpRequest({
    resourceObject,
    body,
  });

  return apiOlympiadParticipant;
};
