import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EnglishData from "../../data/tuition-fees/tuition-fees.json";
import RussianData from "../../data/tuition-fees/tuition-fees-ru.json";
import UzbekData from "../../data/tuition-fees/tuition-fees-uz.json";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";

import { useLanguageDetector } from "../../hooks/useLanguageDetector";

import { Styles } from "./styles/tuition-fees";
import Heading from "../../components/shared/Heading";

const TuitionFees = () => {
  const [tuitionFeesData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper tuition-fees-page">
      <BreadcrumbBox
        title="Tuition Fees"
        image="/assets/images/tuition-fees-1.webp"
      />
      <Styles>
        <section className="tuition-fees">
          <Container>
            <Row>
              <Col xs="12">
                <div className="tuition-fees-content">
                  <div className="tuition">
                    <div className="domestic-tuition">
                      <Heading
                        tag="h4"
                        className="strategy-title"
                        title={tuitionFeesData?.domestic?.title}
                      />
                      <table className="table table-bordered mb-5">
                        <thead>
                          <tr scope="row">
                            <th className="w-50" scope="col">
                              {
                                tuitionFeesData?.domestic?.tuition_table
                                  ?.head[0]
                              }
                            </th>
                            <th className="w-50" scope="col">
                              {
                                tuitionFeesData?.domestic?.tuition_table
                                  ?.head[1]
                              }
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tuitionFeesData?.domestic?.tuition_table?.body.map(
                            (row, index) => (
                              <tr key={index} scope="row">
                                <td className="w-50" scope="col">
                                  {row[0]}
                                </td>
                                <td className="w-50" scope="col">
                                  {row[1]}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="international-tuition">
                      <Heading
                        tag="h4"
                        className="strategy-title"
                        title={tuitionFeesData?.international?.title}
                      />
                      <table className="table table-bordered">
                        <thead>
                          <tr scope="row">
                            <th scope="col" rowSpan={2}>
                              {
                                tuitionFeesData?.international?.tuition_table
                                  ?.head[0]
                              }
                            </th>
                            <th className="text-center" scope="col" colSpan={2}>
                              {
                                tuitionFeesData?.international?.tuition_table
                                  ?.head[1]
                              }
                            </th>
                          </tr>
                          <tr scope="row">
                            <th scope="col">
                              {
                                tuitionFeesData?.international?.tuition_table
                                  ?.head[2]
                              }
                            </th>
                            <th scope="col">
                              {
                                tuitionFeesData?.international?.tuition_table
                                  ?.head[3]
                              }
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tuitionFeesData?.international?.tuition_table?.body.map(
                            (row, index) => (
                              <tr key={index} scope="row">
                                <td className="w-50" scope="col">
                                  {row[0]}
                                </td>
                                <td className="w-25" scope="col">
                                  {row[1]}
                                </td>
                                <td className="w-25" scope="col">
                                  {row[2]}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <p>{tuitionFeesData?.subtitle}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default TuitionFees;
