import React from "react";
import { Styles } from "./styles/olympiad.js";
import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "./data/olympiad.json";
import RussianData from "./data/olympiad-ru.json";
import UzbekData from "./data/olympiad-uz.json";

const OlympiadPage = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <Styles>
      <BreadcrumbBox
        title={data.pageTitle}
        image="/assets/images/banner-2.jpg"
        preTitle={data.preTitle}
        centerTitle={true}
        registerButton={false}
        registerLink="/olympiad/form"
      />
      <div className="main-wrapper olympiad-page">
        <div className="olympiad-stages">
          <div className="stage">
            <p className="paragraph1">{data.paragraph1}</p>
            <div className="d-flex gap-5 align-items-start mb-5">
              <div className="results">
                <h3>{data.results.title}</h3>
                <ul className="list-unstyled">
                  {data.results.list.map((item, index) => (
                    <li key={index}>
                      <div className="d-flex gap-3">
                        <strong>{index + 1}.</strong>
                        <div>
                          <strong>{item.name}</strong>
                          <p>{item.school}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <img
                src="/assets/images/olympiad-process.jpg"
                alt="Olympiad process"
                className="rounded"
              />
            </div>
            <div className="info">
              <p className="paragraph2">{data.paragraph2}</p>
              <div className="info-details">
                <p>
                  {data.paragraph3.preLink}
                  <a
                    href={data.paragraph3.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.paragraph3.link}
                  </a>
                </p>
                <p>
                  {data.paragraph4.preTitle}
                  <strong>{data.paragraph4.title}</strong>
                  {data.paragraph4.postTitle}
                </p>
                <p>
                  {data.paragraph5.preLink}
                  <a
                    href={data.paragraph5.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.paragraph5.link}
                  </a>
                </p>
                <p>
                  {data.paragraph6.preLink}
                  <a
                    href={data.paragraph6.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.paragraph6.link}
                  </a>
                </p>
              </div>
            </div>

            <div className="venue">
              <p>
                📍 <strong>{data.venue.title}</strong>
              </p>
              <p>
                <strong>{data.venue.address.title}</strong>
                {data.venue.address.value}
                <a
                  href={data.venue.address.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.venue.address.link}
                </a>
              </p>
              <p>
                <strong>{data.venue.date.title}</strong>
                {data.venue.date.value}
              </p>
              <p>
                <strong>{data.venue.time.title}</strong>
                {data.venue.time.value}
              </p>
            </div>

            <div className="additional-info">
              <p>{data.paragraph7}</p>
              <p>{data.paragraph8}</p>
              <p className="pt-3">
                <i>{data.paragraph9}</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default OlympiadPage;
